import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";
import LoaderWithBackDrop from "../LoaderWithBackDrop/LoaderWithBackDrop.comp";


const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '55%',
        height: 'auto',
        overflowY: 'scroll',
        overflowX: 'scroll'
    },
    btn: {
        marginTop: 16
    },
}));

function PreRemoveModal(props) {

    const { open, t_id, title, deleteAPI, preRemoveAPI, handleClose } = props

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [delAllowed, setDelAllowed] = useState(false);
    const [denyMessage, setDenyMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(()=>{
        if (t_id) {
            preCheckServiceCall().then(d=>console.log('pre-check service loaded.'));
        }
    }, [t_id])

    const preCheckServiceCall = async () => {
        setWarningMessage('');
        setDenyMessage('');
        if (t_id.length > 0) {
            setLoading(true);
            try {
                const res = await preRemoveAPI(t_id);
                if (!res.deny) {
                    setDelAllowed(true);
                } else {
                    setDelAllowed(false);
                    setDenyMessage(res.deny_message);
                }

                if (res.warning) {
                    setWarningMessage(res.warning_message)
                }

                setLoading(false);
            } catch (e) {
                // error toasted already
                setLoading(false);
            }
        }
    }

    return (

        <>
            <LoaderWithBackDrop loading={loading} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>

                        <h2 id="transition-modal-title">{title}</h2>



                            <Grid container spacing={2}>

                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={10}>
                                    <p>Are you Sure you want to remove ?</p>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>

                                <Grid item xs={12}>
                                </Grid>

                                <Grid item xs={2}>
                                </Grid>

                                <Grid item xs={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn}
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    {
                                        delAllowed && <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn}
                                            onClick={()=>deleteAPI(t_id)}
                                        >
                                            Delete
                                        </Button>
                                    }
                                </Grid>


                            </Grid>

                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default PreRemoveModal;
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayTableWithImport from "../../../components/DisplayTableWithImport/DisplayTableWithImport.comp";

import {
    ProfileLookup
} from "../../../services/organization.service";
import {useAuth} from "../../../context/auth";
import {OrgCRUD_Services} from "../../../services/organization.service";
import NativeSelect from "@material-ui/core/NativeSelect";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme)=>({
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
}));

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Email', key: 0, json_key: 'email', data_key: 'email'},
    {name: 'Institute Code', key: 1, json_key: 'institute_code', data_key: 'institute_code'},
    {name: 'Section Name', key: 2, json_key: 'section_code', data_key: 'section_code'},
    {name: 'Section Level', key: 3, json_key: 'section_level', data_key: 'section_level'},
]

const tableCols = [
    {
        label: 'Email',
        field: 'email', width: 150
    },
    {
        label: 'Institute Name',
        field: 'institute_code', width: 150
    },
    {
        label: 'Section Name',
        field: 'section_code', width: 150
    },
    {
        label: 'Section Level',
        field: 'section_level', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function ProfileLookup_Org() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                const _all_orgs = result.data.ORGs.map(item => item.org_code)
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleChangeOrgSelect = (e) => {
        setActiveORG(e.target.value);
    }

    const apiCombinedFunction = () => ({
        list:  () => ProfileLookup.listData(activeORG),
        post: (data) => ProfileLookup.postData(activeORG, data),
        delete: (id) => ProfileLookup.deleteEntity(activeORG, id),
        update: (id, data) => ProfileLookup.updateEntity(activeORG, id, data)
    })

    const memoizedAPIs = useMemo(apiCombinedFunction, [activeORG])

    return (
        <Container component="main">

            <NativeSelect
                className={classes.selectEmpty}
                value={activeORG}
                name="active_org"
                onChange={handleChangeOrgSelect}
                inputProps={{ 'aria-label': 'active_org' }}
            >
                <option value="" disabled>
                    Select Organization
                </option>
                {
                    allORGs.map(item => <option value={item}>{item}</option>)
                }
            </NativeSelect>
            {
                activeORG && <DisplayTableWithImport
                    title={'Profile Lookup'}
                    modal_title={'Profile Lookup - Data Preview'}
                    sheet_name={'Profile Lookup'}
                    columns={columns}
                    tableCols={tableCols}
                    listAPI={memoizedAPIs.list}
                    postAPI={memoizedAPIs.post}
                    deleteAPI={memoizedAPIs.delete}
                    updateAPI={memoizedAPIs.update}
                />
            }
            <LoaderWithBackDrop loading={loading} />
        </Container>

    );
}

import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    Level7
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Full Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
    {name: 'Level-6', key: 3, json_key: 'lvl6_code', data_key: 'lvl6_code'},
    {name: 'DQC#', key: 6, json_key: 'dqc_no', data_key: 'dqc_no'},
    {name: 'GSM#', key: 5, json_key: 'gsm_no', data_key: 'gsm_no'},
    {name: 'DQ#', key: 7, json_key: 'dq_no', data_key: 'dq_no'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'Level 6',
        field: 'lvl6_code', width: 150
    },
    {
        label: 'DQC#',
        field: 'dqc_no', width: 150
    },
    {
        label: 'GSM#',
        field: 'gsm_no', width: 150
    },
    {
        label: 'DQ#',
        field: 'dq_no', width: 150
    },
    {
        label: '[AGG] Assess 8',
        field: 'code_agg_a8', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function Level7Page() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const cat4_code_data = row.code;
        row.code_agg_a8 = <Link to={`/meta_data/assess8/q=${cat4_code_data}`} className={'meta-data-table-entity-link'} >{cat4_code_data}</Link>;

        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level 8'}
            modal_title={'Level 8 - Data Preview'}
            sheet_name={'Level8'}
            columns={columns}
            tableCols={tableCols}
            search_query={search_query}
            tableSpRender={table_row__additional_rendering}
            listAPI={Level7.listData}
            postAPI={Level7.postData}
            deleteAPI={Level7.deleteEntity}
            updateAPI={Level7.updateEntity}
            preRemoveAPI={Level7.preRemoveCheck}
        />
    );
}

import React, {useState, useEffect} from 'react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Table, TableBody, TableHead, TableRow, TableCell }from '@material-ui/core';

import Backdrop from "@material-ui/core/Backdrop";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#fff1f1'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));

function PackageSelectionModal(props) {

    const { open, handleClose, org_code, packagesMeta, packCodesMap, updatePresence, saveChanges } = props

    const classes = useStyles();

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className={classes.modalPaper}>

                        <h3 id="transition-modal-title">{'Selecting Packages For => ' + org_code}</h3>
                        <br />

                        {
                            packCodesMap && <Table>
                                <TableHead className={classes.table_head_bg}>
                                    <TableRow>
                                        <TableCell className={classes.table_cell_white}>Id</TableCell>
                                        <TableCell className={classes.table_cell_white}>Code</TableCell>
                                        <TableCell className={classes.table_cell_white}>Full Name</TableCell>
                                        <TableCell className={classes.table_cell_white}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        packagesMeta.map( (item, index) => (
                                            <TableRow className={packCodesMap[item.code] ? classes.tr_act : classes.tr_d_act}>
                                                <TableCell>{item.id}</TableCell>
                                                <TableCell>{item.code}</TableCell>
                                                <TableCell>{item.full_name}</TableCell>
                                                <TableCell>
                                                    {
                                                        packCodesMap[item.code] ?
                                                            <Button
                                                                onClick={()=>updatePresence(item.code, false)}
                                                                className={classes.d_act_btn}>{`Remove From=> ${org_code}`}</Button>
                                                            :
                                                            <Button
                                                                onClick={()=>updatePresence(item.code, true)}
                                                                className={classes.act_btn}>{`Add to=> ${org_code}`}</Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        }

                        <br />
                        <Button
                            style={{
                                marginLeft: '20px',
                                width: '25%'
                            }}
                            variant="contained"  color="primary"
                            onClick={saveChanges}>Save Changes</Button>

                    </div>

                </Fade>
            </Modal>
        </>
    );

}

export default PackageSelectionModal
import React, {useState} from 'react';
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    Packages
} from "../../../services/metadata.service";
import {Link, useParams} from "react-router-dom";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api

const badges_response_parser = (data) => {
    let str = data.trim();

    let str_arr = str.split('(');
    let output = []
    for (let i=1; i<str_arr.length; i++) {
        const option = str_arr[i].split(')')[0];
        if (option.length > 1) {
            output.push(option);
        }
    }
    if (output.length === 0) {
        return data
    }
    return output;
}

const additional_json_rendering_of_Badges = (json_obj) => {
    if (typeof json_obj['badges_list'] === 'object') {
        json_obj['badges_list'] = JSON.stringify(json_obj['badges_list']);
        json_obj['badges_date_type'] = 'JSON'
    } else {
        json_obj['badges_date_type'] = 'STRING'
    }
    return json_obj;
}

const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Full Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
    {name: 'Badges', key: 3, json_key: 'badges_list', data_key: 'badges_list_data', data_parser: badges_response_parser},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', sort: 'asc', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', sort: 'asc', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'asc', width: 150
    },
    {
        label: 'Badges',
        field: 'badges_list', sort: 'disabled', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]



const PackagesData = (props) => {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {

        let badges_output = '';
        if (row.badges_date_type === 'JSON') {
            const badges_data_cp = JSON.parse(row.badges_list);

            if (Array.isArray(badges_data_cp)) {
                let spans = [];
                for (let item of badges_data_cp) {
                    spans.push(<span className={'meta-data-table-array-span'}>{item}</span>);

                    badges_output += `(${item})`
                    badges_output += '\n'
                }
                row.badges_list = spans;
            } else {
                row.badges_list = badges_data_cp;
                badges_output = badges_data_cp;
            }
        } else {
            badges_output = row.badges_list
        }
        row.badges_list_data = badges_output;
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;
        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Packages [Badge Bundles]'}
            modal_title={'Packages- Data Preview'}
            sheet_name={''}
            columns={columns}
            tableCols={tableCols}
            jsonSpRender={additional_json_rendering_of_Badges}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Packages.listData}
            postAPI={Packages.postData}
            deleteAPI={Packages.deleteEntity}
            updateAPI={Packages.updateEntity}
            preRemoveAPI={Packages.preRemoveCheck}
        />
    );
}

export default PackagesData;



import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableHead, TableRow, TableCell }from '@material-ui/core';
import AddUserModal from '../components/AddUserModal.comp';
import UserPermissionModal from '../components/UserPermissionsModal_v2.comp';

import {addNewUser, listAllUsers, removeAdminPortalUser, updateUserEntity, updateUserPermissions} from "../../../services/users.service";
import {toastWarning, formatDate} from "../../../utils/utils";
import {
    USER_TYPE_VERBOSE,
    USER_ACCESS_LEVEL_VERBOSE,
    NESTED_ACL_KEYS,
    USER_ACCESS_LEVEL
} from "../../../utils/constants";
import {AccountServices} from "../../../services/organization.service";
import DeleteIcon from "@material-ui/icons/Delete";
import {useAuth} from "../../../context/auth";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
    },
}));

function List_User(){

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);
    const [permissionSections, setPermissionSections] = useState([]);

    const [updatingPermissionOfUser, setUpdatingPermissionOfUser] = useState(undefined);

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);

    const [showCreateBtn, setShowCreateBtn] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));

        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                perms[NESTED_ACL_KEYS.USERS_ADD] ? setShowCreateBtn(true) : setShowCreateBtn(false);
                perms[NESTED_ACL_KEYS.USERS_UPDATE_ENTITY] ? setShowUpdateBtn(true) : setShowUpdateBtn(false);
                perms[NESTED_ACL_KEYS.USERS_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                setShowCreateBtn(false)
                setShowUpdateBtn(false)
                setShowDeleteBtn(false)
            } else {
                setShowCreateBtn(true)
                setShowUpdateBtn(true)
                setShowDeleteBtn(true)
            }
        }
    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {
            const result = await listAllUsers();
            if (result.data && result.data.users) {
                setPageData(result.data.users);
                if (result.data.sections) {
                    setPermissionSections(result.data.sections)
                }
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const openCreateModal = () => {
        setShowAddUserModal(true);
    }

    const enableUserService = async (index) => {
        const is_active = true;
        let data_copy = pageData;
        const member_id = data_copy[index].member_id

        setLoading(true);
        try {
            await updateUserEntity(member_id, is_active);
            data_copy[index].is_active = is_active;
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const disableUserService = async (index) => {
        const is_active = false;
        let data_copy = pageData;
        const member_id = data_copy[index].member_id

        setLoading(true);
        try {
            await updateUserEntity(member_id, is_active);
            data_copy[index].is_active = is_active;
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const addAdminPortalUserService = async (email) => {
        setLoading(true);
        try {
            const result = await addNewUser(email);
            if (result.data && result.data.new_user) {
                let dataCopy = pageData;
                dataCopy.push(result.data.new_user);
                setPageData([...dataCopy]);
            }
        } catch (e) {
            // error already toasted
            console.log('User-Services -- exception occurred ==> ', e);
        }
        setLoading(false);
        setShowAddUserModal(false);
    }

    const invokeDeleteUserService = async (member_id) => {
        setLoading(true);
        try {
            const result = await removeAdminPortalUser(member_id);
            if (result.data && result.data.deleted) {
                let updateData = pageData.filter(account => account.member_id !== member_id);
                setPageData([...updateData]);
            }
        } catch (e) {
            // error already toasted
            console.log('AccountServices -- exception occurred ==> ', e);
        }
        setLoading(false);
    }

    const openUserPermissionModal = (user) => {
        setUpdatingPermissionOfUser(user);
        setShowPermissionsModal(true);
    }

    const updateUserPermissionsService = async (data) => {
        setLoading(true);
        try {
            const result = await updateUserPermissions(updatingPermissionOfUser.member_id,data);
            // updated: true,
            //     updated_user: user
            if (result.data && result.data.updated_user) {
                const new_user = result.data.updated_user
                let dataCopy = pageData;
                dataCopy = dataCopy.map( usr => {
                   if(usr.member_id === new_user.member_id) {
                       return new_user;
                   } else {
                       return usr;
                   }
                });
                setPageData([...dataCopy]);
            }
        } catch (e) {
            // error already toasted
            console.log('User-Services -- exception occurred ==> ', e);
        }
        setLoading(false);

        // close modal when done
        setShowPermissionsModal(false);
        setUpdatingPermissionOfUser(undefined);
    }

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'flex-left', alignItems: 'center', width: '100%'}}>

            <div className={classes.headerInputGroup}>
                {
                    showCreateBtn && <Button
                        variant="contained"  color="primary"
                        onClick={openCreateModal}
                    >Add User</Button>
                }
            </div>
            </div>

            <div className={classes.paper}>
                <h3>All Users</h3>
            </div>

            <Table width='100%'>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        <TableCell className={classes.table_cell_white}>Sr#</TableCell>
                        <TableCell className={classes.table_cell_white}>Email</TableCell>
                        <TableCell className={classes.table_cell_white}>Member-ID</TableCell>
                        <TableCell className={classes.table_cell_white}>Active</TableCell>
                        <TableCell className={classes.table_cell_white}>Access Level</TableCell>
                        <TableCell className={classes.table_cell_white}>Created</TableCell>
                        <TableCell className={classes.table_cell_white}>Updated</TableCell>
                        <TableCell className={classes.table_cell_white}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        pageData.map( (item, index) => (
                            <TableRow className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                <TableCell style={{verticalAlign: 'middle'}}>{index + 1}</TableCell>
                                <TableCell style={{verticalAlign: 'middle'}}>{item.email}</TableCell>
                                <TableCell style={{verticalAlign: 'middle'}}>{item.member_id}</TableCell>
                                <TableCell style={{verticalAlign: 'middle'}}>{item.is_active ? 'YES' : 'NO'}</TableCell>
                                <TableCell style={{verticalAlign: 'middle'}}>{USER_ACCESS_LEVEL_VERBOSE[item.access_level]}</TableCell>
                                <TableCell>{formatDate(item.createdAt)},<br />{item.createdBy}</TableCell>
                                <TableCell>{formatDate(item.createdAt)},<br />{item.updatedBy}</TableCell>
                                <TableCell style={{verticalAlign: 'middle'}}>
                                    {
                                        showUpdateBtn && <>
                                            {
                                                item.is_active ?
                                                    <Button onClick={()=>disableUserService(index)}
                                                            className={classes.d_act_btn}>Disable</Button> :
                                                    <Button onClick={()=>enableUserService(index)}
                                                            className={classes.act_btn}>Enable</Button>
                                            }
                                            <Button onClick={()=>openUserPermissionModal(item)}
                                                    style={{
                                                        marginLeft: '5px',
                                                    }}
                                                    variant="contained"  color="primary">Permissions</Button>
                                        </>
                                    }
                                    {
                                        showDeleteBtn && <DeleteIcon
                                            onClick={()=> {
                                                invokeDeleteUserService(item.member_id);
                                            }}
                                            className={'meta-data-table-action-btn'} fontSize={'large'} color={'error'}/>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>


            <AddUserModal
                open={showAddUserModal}
                createAccountCallback={addAdminPortalUserService}
                handleClose={ () => setShowAddUserModal(false) }
            />

            <UserPermissionModal
                open={showPermissionsModal}
                user={updatingPermissionOfUser}
                sections={permissionSections}
                updatePermissionsCallback={updateUserPermissionsService}
                handleClose={ () => {
                    setShowPermissionsModal(false);
                    setUpdatingPermissionOfUser(undefined);
                } }
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default List_User;
import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import {toastWarning, formatDate} from "../../../utils/utils";
import {OrgOrderServices, OrgCRUD_Services} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddOrderModal from '../components/AddOrderModal.comp';
import {useAuth} from "../../../context/auth";
import {LICENSES_ORDER_STATUS, LICENSES_ORDER_STATUS_VERBOSE} from "../../../utils/constants";
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));


const headers = [
    { id: "sr", label: "Sr.#", alignment: "left", sort: true },
    { id: "id", label: "Order Details", alignment: "left", sort: false },
    { id: "license_count", label: "License Count", alignment: "left", sort: true },
    { id: "license_type", label: "License Type", alignment: "left", sort: true },
    { id: "status", label: "Status", alignment: "left", sort: true },
    { id: "createdAt", label: "Created", alignment: "left", sort: true },
    { id: "updatedAt", label: "Updated", alignment: "left", sort: true },
]

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function Orders_ORG(){

    const classes = useStyles();

    const { authUser } = useAuth();


    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("sr");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [ordersData, setOrdersData] = useState([]);

    const [showAddOrderModal, setShowAddOrderModal] = useState(false);

    const [showCreateBtn, setShowCreateBtn] = useState(true);


    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadOrdersData().then(d => console.log('orders data loaded!', d));
        }

        // // // ACL layer code for specific page have to be added, following code block is copied from accounts page.
        // if (authUser) {
        //     if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
        //         const perms = authUser.permissions;
        //         perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_ADD] ? setShowCreateBtn(true) : setShowCreateBtn(false);
        //         perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
        //     } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
        //         setShowCreateBtn(false)
        //         setShowDeleteBtn(false)
        //     } else {
        //         setShowCreateBtn(true)
        //         setShowDeleteBtn(true)
        //     }
        // }
    }, [activeORG]);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => `${item.org_code} (${item.org_name})`);
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0].split(" ")[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = ordersData.filter(field_filter_function);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const handleChangeOrgSelect = (value) => {
        if (value) {
            const code = value.split(" ")[0];
            setActiveORG(code);
          }
    }

    const loadOrdersData = async () => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await OrgOrderServices.list_orders(activeORG);
                if (result.data && result.data.orders) {
                    setOrdersData(result.data.orders);
                    console.log('nav: result.data.orders: ', result.data.orders);
                }
            } catch (e) {
                // error already toasted
            }
            setLoading(false);
        }
    }

    const approveOrder = async (id) => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.approve_order(id);
            if (result.data && result.data.success) {
                let existingOrder = ordersData;
                for (let order of existingOrder) {
                    if (order.id === id) {
                        order.status = LICENSES_ORDER_STATUS.APPROVED
                    }
                }
                setOrdersData(existingOrder);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const rejectOrder = async (id) => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.reject_order(id);
            if (result.data && result.data.success) {
                let existingOrder = ordersData;
                for (let order of existingOrder) {
                    if (order.id === id) {
                        order.status = LICENSES_ORDER_STATUS.REJECTED
                    }
                }
                setOrdersData(existingOrder);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const createOrderAnalyticsPortal = () => {
        if (activeORG) {
            setShowAddOrderModal(true);
        } else {
            toastWarning('in order to create an order, please select an organization first!')
        }
    }

    const createOrderAnalyticsPortalService = async (licenses_count, license_type, order_details) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await OrgOrderServices.create_order(activeORG, order_details,
                    licenses_count, license_type);
                if (result.data && result.data.order) {
                    let orders = ordersData;
                    orders.push(result.data.order);
                    setOrdersData([...orders]);
                }
            } catch (e) {
                // error already toasted
                console.log('OrgOrderServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('in order to create an order, please select an organization first!')
        }

        setShowAddOrderModal(false);
    }


    const rowsWithIndices = filteredRows.map((row, index) => {
        return { ...row, sr: index + 1 };
    });
    const slicedRows = rowsWithIndices
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    // Find the object in allORGs that has the org_code matching activeORG
    const default_org = allORGs.find(org => org.split(" ")[0] === activeORG);

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

            <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>
                <div style={{ width: '250px' }}>
                {allORGs && allORGs.length > 0 && default_org && (
                    <Autocomplete
                    id="active_org"
                    value={default_org}
                    onChange={(event, newValue) => {
                        handleChangeOrgSelect(newValue);
                    }}
                    options={allORGs}
                    getOptionLabel={(option) => option}
                    className={classes.selectEmpty}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Organization" variant="outlined" fullWidth />
                    )}
                    />
                )}
                </div>
                <div className={classes.headerInputGroup}>
                    {
                        showCreateBtn && <Button
                        style={
                            {marginLeft: '10px', marginTop: '15px'}
                        }
                            variant="contained"  color="primary"
                            onClick={createOrderAnalyticsPortal}
                        >Create Order</Button>
                    }
                </div>
            
            </div>

            <div className={classes.seaerchTextField}>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                <TextField
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                onClick={() => setSearchText('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            </div>

            </div>



            <div className={classes.paper}>
                <h3>All Orders</h3>
            </div>

            {
                <Table>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        {
                        headers.map((item) =>
                            <EnhancedTableCell
                                id={item.id}
                                className={classes.table_cell_white} 
                                alignment={item.alignment}
                                sort={item.sort}
                                orderBy={orderBy}
                                order={order}
                                onRequestSort={handleRequestSort}
                            >
                                {item.label}
                            </EnhancedTableCell>
                        )
                        }
                        <TableCell className={classes.table_cell_white}>Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            slicedRows.map( (order, index) => (
                                <TableRow className={true ? classes.tr_act : classes.tr_d_act}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{order.order_details}</TableCell>
                                    <TableCell>{order.license_count}</TableCell>
                                    <TableCell>{order.license_type}</TableCell>
                                    <TableCell>{LICENSES_ORDER_STATUS_VERBOSE[order.status]}</TableCell>
                                    <TableCell>{formatDate(order.createdAt)},<br />{order.createdBy}</TableCell>
                                    <TableCell>{formatDate(order.createdAt)},<br />{order.updatedBy}</TableCell>
                                    {
                                        order.status === LICENSES_ORDER_STATUS.PENDING ? <TableCell>
                                            <Button onClick={()=>rejectOrder(order.id)}
                                                className={classes.d_act_btn}>Reject</Button>
                                            <Button onClick={()=>approveOrder(order.id)}
                                                className={classes.act_btn}>Approve</Button>
                                        </TableCell> : <TableCell>{"No Action!"}</TableCell>
                                    }

                                </TableRow>
                            ) )

                        }
                    </TableBody>
                </Table>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <AddOrderModal
                open={showAddOrderModal}
                createOrderCallback={createOrderAnalyticsPortalService}
                handleClose={ () => setShowAddOrderModal(false) }
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
};

export default Orders_ORG;
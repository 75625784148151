import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    Category4
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Full Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
    {name: 'Category-3', key: 3, json_key: 'cat3_code', data_key: 'cat3_code'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'Category 3',
        field: 'cat3_code', width: 150
    },
    {
        label: '[AGG] Assess 8',
        field: 'code_agg_a8', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function Category4Page() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const cat4_code_data = row.code;
        row.code_agg_a8 = <Link to={`/meta_data/assess8/q=${cat4_code_data}`} className={'meta-data-table-entity-link'} >{cat4_code_data}</Link>;
        
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Category 4'}
            modal_title={'Category 4 - Data Preview'}
            sheet_name={'Category 4-KSAVP'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Category4.listData}
            postAPI={Category4.postData}
            deleteAPI={Category4.deleteEntity}
            updateAPI={Category4.updateEntity}
            preRemoveAPI={Category4.preRemoveCheck}
        />
    );
}

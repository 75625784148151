import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import {toastWarning, formatDate} from "../../../utils/utils";
import {AccountServices, OrgCRUD_Services} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddAccountModal from '../components/AddAccountModal.comp';
import AddSubOrgAccountModal from '../components/AddSubOrgAccountModal.comp';
import {USER_ACCESS_LEVEL, USER_ACCESS_LEVEL_VERBOSE} from "../../../utils/constants";
import {NESTED_ACL_KEYS} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";

import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));



const headers = [
    { id: "sr", label: "Sr.#", alignment: "left", sort: true },
    { id: "email", label: "Email", alignment: "left", sort: true },
    { id: "member_id", label: "Member-ID", alignment: "left", sort: true },
    { id: "access_level", label: "Access-Level", alignment: "left", sort: true },
    { id: "institute_code", label: "Inst-Code", alignment: "left", sort: true },
    { id: "createdAt", label: "Created", alignment: "left", sort: true },
    { id: "updatedAt", label: "Updated", alignment: "left", sort: true },
    { id: "lastLogin", label: "Last Login", alignment: "left", sort: true}
]

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function Accounts_ORG(){

    const classes = useStyles();

    const { authUser } = useAuth();

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("sr");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [accountsData, setAccountsData] = useState([]);

    const [showAddAccountModal, setShowAddAccountModal] = useState(false);
    const [showAddSubOrgAccountModal, setShowAddSubOrgAccountModal] = useState(false);

    const [showCreateBtn, setShowCreateBtn] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadAccountsData().then(d => console.log('accounts data loaded!', d));
        }

        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_ADD] ? setShowCreateBtn(true) : setShowCreateBtn(false);
                perms[NESTED_ACL_KEYS.ORG_ACCOUNTS_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                setShowCreateBtn(false)
                setShowDeleteBtn(false)
            } else {
                setShowCreateBtn(true)
                setShowDeleteBtn(true)
            }
        }
    }, [activeORG]);


    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => `${item.org_code} (${item.org_name})`);
                setAllORGs(_all_orgs);
                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0].split(" ")[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = accountsData.filter(field_filter_function);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const handleChangeOrgSelect = (value) => {
        if (value) {
            const code = value.split(" ")[0];
            setActiveORG(code);
          }
    }

    const loadAccountsData = async () => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.list_accounts(activeORG);
                if (result.data && result.data.accounts) {
                    setAccountsData(result.data.accounts);
                }
            } catch (e) {
                // error already toasted
            }
            setLoading(false);
        }
    }

    const createAccountAnalyticsPortal = () => {
        if (activeORG) {
            setShowAddAccountModal(true);
        } else {
            toastWarning('in order to create an account, please select an organization first!')
        }
    }

    const createSubOrgAccountAnalyticsPortal = () => {
        if (activeORG) {
            setShowAddSubOrgAccountModal(true);
        } else {
            toastWarning('in order to create an account, please select an organization first!')
        }
    }

    const createAccountAnalyticsPortalService = async (email) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.add_account(activeORG, email);
                if (result.data && result.data.new_account) {
                    let accounts = accountsData;
                    accounts.push(result.data.new_account);
                    setAccountsData([...accounts]);
                }
            } catch (e) {
                // error already toasted
                console.log('AccountServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('in order to create an account, please select an organization first!')
        }

        setShowAddAccountModal(false);
    }

    const createSubOrgAccountAnalyticsPortalService = async (email, institute_code) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.add_account(activeORG, email, institute_code);
                if (result.data && result.data.new_account) {
                    let accounts = accountsData;
                    accounts.push(result.data.new_account);
                    setAccountsData([...accounts]);
                }
            } catch (e) {
                // error already toasted
                console.log('AccountServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('in order to create an account, please select an organization first!')
        }

        setShowAddSubOrgAccountModal(false);
    }

    const invokeDeleteAccountService = async (member_id) => {
        if (activeORG) {
            setLoading(true);
            try {
                const result = await AccountServices.remove_account(activeORG, member_id);
                if (result.data && result.data.deleted) {
                    let accounts = accountsData.filter(account => account.member_id !== member_id);
                    setAccountsData([...accounts]);
                }
            } catch (e) {
                // error already toasted
                console.log('AccountServices -- exception occurred ==> ', e);
            }
            setLoading(false);
        } else {
            toastWarning('please select an valid organization first!')
        }
    }

    const rowsWithIndices = filteredRows.map((row, index) => {
        return { ...row, sr: index + 1 };
    });

    const slicedRows = rowsWithIndices
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    // Find the object in allORGs that has the org_code matching activeORG
    const default_org = allORGs.find(org => org.split(" ")[0] === activeORG);

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>

                <div style={{ width: '250px' }}>
                {allORGs && allORGs.length > 0 && default_org && (
                    <Autocomplete
                    id="active_org"
                    value={default_org}
                    onChange={(event, newValue) => {
                        handleChangeOrgSelect(newValue);
                    }}
                    options={allORGs}
                    getOptionLabel={(option) => option} // assuming allORGs is an array of strings
                    className={classes.selectEmpty}
                    renderInput={(params) => (
                        <TextField 
                        {...params} 
                        label="Select Organization" 
                        variant="outlined" 
                        fullWidth             
                        />
                    )}
                    />
                )}
                </div>

                <div className={classes.headerInputGroup}>
                    {
                        showCreateBtn && <Button
                        style={
                            {marginLeft: '10px', marginTop: '15px'}
                        }
                            variant="contained"  color="primary"
                            onClick={createAccountAnalyticsPortal}
                        >Create Account</Button>
                    }
                    {
                        showCreateBtn && <Button
                        style={
                            {marginLeft: '10px', marginTop: '15px'}
                        }
                            variant="contained"  color="primary"
                            onClick={createSubOrgAccountAnalyticsPortal}
                        >Create Sub-Org Account</Button>
                    }
                </div>
                </div>
                <div className={classes.seaerchTextField}>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                    <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        placeholder="Search…"
                        className={classes.textField}
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                    onClick={() => setSearchText('')}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                </div>
            </div>


            <div className={classes.paper}>
                <h3>All Accounts</h3>
            </div>

            {
                <Table>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        {
                        headers.map((item) =>
                            <EnhancedTableCell
                                id={item.id}
                                className={classes.table_cell_white} 
                                alignment={item.alignment}
                                sort={item.sort}
                                orderBy={orderBy}
                                order={order}
                                onRequestSort={handleRequestSort}
                            >
                                {item.label}
                            </EnhancedTableCell>
                        )
                        }
                        <TableCell className={classes.table_cell_white}>Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            slicedRows.map( (account, index) => 
                                <TableRow className={true ? classes.tr_act : classes.tr_d_act}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{account.email}</TableCell>
                                    <TableCell>{account.member_id}</TableCell>
                                    <TableCell>{USER_ACCESS_LEVEL_VERBOSE[account.access_level]}</TableCell>
                                    <TableCell>{account.institute_code}</TableCell>
                                    <TableCell>{formatDate(account.createdAt)},<br />{account.createdBy}</TableCell>
                                    <TableCell>{formatDate(account.updatedAt)},<br />{account.updatedBy}</TableCell>
                                    <TableCell>{account.login_time ? formatDate(account.login_time) : ""}</TableCell>
                                    <TableCell>
                                        {
                                            showDeleteBtn && <Button onClick={()=>invokeDeleteAccountService(account.member_id)}
                                                                     className={classes.d_act_btn}>Delete</Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <AddAccountModal
                open={showAddAccountModal}
                createAccountCallback={createAccountAnalyticsPortalService}
                handleClose={ () => setShowAddAccountModal(false) }
            />

            <AddSubOrgAccountModal
                open={showAddSubOrgAccountModal}
                createAccountCallback={createSubOrgAccountAnalyticsPortalService}
                handleClose={ () => setShowAddSubOrgAccountModal(false) }
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
};

export default Accounts_ORG;
import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
    palette: {
        primary: { 500: '#467fcf' },
    },
    overrides: {
        MuiTableCell: {
          root: {
            '&': {
              color: '212121', //'#212529',
              fontWeight: '250',
            },
          },
        },
    },
    
})
export default theme
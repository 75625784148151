import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import {getPresenceMap, getMapFromObjectListByKey, toastWarning} from "../../../utils/utils";
import {OrgCRUD_Services, AllPackagesServices} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';

import PackageSelectionModal from '../components/PackagesSelectionModal.comp';
import {Packages} from "../../../services/metadata.service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));

function All_Packages(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [openSelectionModal, setOpenSelectionModal] = useState(false);

    // const [packCodes, setPackCodes] = useState([]);
    const [packCodesMap, setPackCodesMap] = useState({});

    const [packagesMeta, setPackagesMeta] = useState([]);
    const [packagesMetaMap, setPackagesMetaMap] = useState({});

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
        loadPackagesMeta().then(d => console.log('pack-meta data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadPackCodes().then(d => console.log('pack-codes data loaded!', d));
        }
    }, [activeORG]);


    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => `${item.org_code} (${item.org_name})`);
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0].split(" ")[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadPackagesMeta = async () => {
        setLoading(true);

        try {
            const result = await Packages.listData();
            if (result.data && result.data.metaData) {
                const all_metadata = result.data.metaData
                setPackagesMeta(all_metadata);
                setPackagesMetaMap(getMapFromObjectListByKey(all_metadata, 'code'))
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadPackCodes = async () => {
        setLoading(true);

        if (activeORG !== '') {
            try {
                const result = await AllPackagesServices.listPacks(activeORG);
                if (result.data && result.data.pack_codes) {
                    const codes = result.data.pack_codes;
                    // setPackCodes(codes)
                    setPackCodesMap(getPresenceMap(codes))
                }
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const updatePresence = (code, added) => {
        const presence = {
            ...packCodesMap,
            [code]: added
        }
        setPackCodesMap(presence);
    }

    const openSelectionClick = () => {
        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            setOpenSelectionModal(true);
        }
    }

    const handleChangeOrgSelect = (value) => {
        if (value) {
            const code = value.split(" ")[0];
            setActiveORG(code);
          }
    }

    const saveChanges = async () => {
        setLoading(true);

        const presentCodes = packCodesMap;
        let pack_codes = [];
        for (let code of Object.keys(presentCodes)) {
            if (presentCodes[code]) {
                pack_codes.push(code);
            }
        }

        try {
            await AllPackagesServices.mapPacks(pack_codes, activeORG);
        } catch (e) {
            // error already toasted
        }

        setOpenSelectionModal(false);
        setLoading(false);
    }

    // Find the object in allORGs that has the org_code matching activeORG
    const default_org = allORGs.find(org => org.split(" ")[0] === activeORG);

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-left', alignItems: 'center', width: '100%'}}>

                <div style={{ width: '250px' }}>
                {allORGs && allORGs.length > 0 && default_org && (
                    <Autocomplete
                    id="active_org"
                    value={default_org}
                    onChange={(event, newValue) => {
                        handleChangeOrgSelect(newValue);
                    }}
                    options={allORGs}
                    getOptionLabel={(option) => option} // assuming allORGs is an array of strings
                    className={classes.selectEmpty}
                    renderInput={(params) => (
                        <TextField 
                        {...params} 
                        label="Select Organization" 
                        variant="outlined" 
                        fullWidth             
                        />
                    )}
                    />
                )}
                </div>

                <div className={classes.headerInputGroup}>

                    <Button
                        style={
                            {marginLeft: '10px', marginTop: '15px'}
                        }
                        variant="contained"  color="primary"
                        onClick={openSelectionClick}
                    >Select Packages</Button>
                </div>
            </div>
            <div className={classes.paper}>
                <h3>All Packages</h3>
            </div>

            {
                packCodesMap && <Table>
                    <TableHead className={classes.table_head_bg}>
                        <TableRow>
                            <TableCell className={classes.table_cell_white}>Sr.#</TableCell>
                            <TableCell className={classes.table_cell_white}>Code</TableCell>
                            <TableCell className={classes.table_cell_white}>Full Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(packCodesMap).map( (code, index) => (
                                packCodesMap[code] && packagesMetaMap[code] ?
                                <TableRow className={true ? classes.tr_act : classes.tr_d_act}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{packagesMetaMap[code].code}</TableCell>
                                    <TableCell>{packagesMetaMap[code].full_name}</TableCell>
                                </TableRow> : undefined
                            ))
                        }
                    </TableBody>
                </Table>
            }

            {
                <PackageSelectionModal
                    open={openSelectionModal}
                    org_code={activeORG}
                    packagesMeta={packagesMeta}
                    packCodesMap={packCodesMap}
                    updatePresence={updatePresence}
                    saveChanges={saveChanges}
                    handleClose={ ()=> {
                        setOpenSelectionModal(false);
                    } }
                />
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default All_Packages;
import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import {toastWarning, formatDate} from "../../../utils/utils";
import {OrgCRUD_Services, AreaUIConfServices} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { SketchPicker } from 'react-color'
import UploadImageDialogue from "../components/uploadImageDialogue.comp";
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    colorCube: {
        height: 40,
        width: 60,
        border: '1px solid #000',
        cursor: "pointer"
    },
    imageDiv: {
        height: 60,
        marginTop: -10,
        cursor: 'pointer'
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));

const headers = [
    { id: "sr", label: "Sr.#", alignment: "left", sort: true },
    { id: "area_code", label: "Code", alignment: "left", sort: false },
    { id: "icon_url", label: "Icon", alignment: "left", sort: false },
    { id: "theme_color", label: "Theme Color", alignment: "left", sort: false },
    { id: "badge_background", label: "Badge Background", alignment: "left", sort: false },
    { id: "lock_background", label: "Lock Background", alignment: "left", sort: false },
    { id: "lock_text_color", label: "Lock Text Color", alignment: "left", sort: false },
    { id: "primary_color", label: "Primary Color", alignment: "left", sort: false },
    { id: "primary_color_locked", label: "Primary Color Locked", alignment: "left", sort: false },
    { id: "secondary_color", label: "Secondary Color", alignment: "left", sort: false },
    { id: "secondary_color_locked", label: "Secondary Color Locked", alignment: "left", sort: false },
    { id: "createdAt", label: "Created", alignment: "left", sort: true },
    { id: "updatedAt", label: "Updated", alignment: "left", sort: true },
]


function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function Area_UI_Conf(){

    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("sr");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [pageData, setPageData] = useState([]);
    const [updateIconOfItem, setUpdateIconOfItem] = useState(-1);
    const [itemSelected, setItemSelected] = useState(-1);
    const [columnSelected, selectColumnSelected] = useState('');

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadPageData().then(d => console.log('table data loaded!', d));
        }
    }, [activeORG]);

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = pageData.filter(field_filter_function);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => `${item.org_code} (${item.org_name})`);
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0].split(" ")[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadPageData = async () => {
        if (activeORG !== '') {
            setLoading(true);

            try {
                const result = await AreaUIConfServices.list(activeORG);
                if (result.data && result.data.areas) {
                    setPageData(result.data.areas);
                }
            } catch (e) {
                // error already toasted
            }

            setLoading(false);
        }
    }

    const handleChangeOrgSelect = (value) => {
        if (value) {
            const code = value.split(" ")[0];
            setActiveORG(code);
          }
    }

    const clickedColorDiv = (index, colorColumn) => {
        if (index === itemSelected) {
            if (colorColumn === columnSelected) {
                setItemSelected(-1);
            } else {
                selectColumnSelected(colorColumn);
            }
        } else {
            setItemSelected(index);
            selectColumnSelected(colorColumn);
        }
    }

    const finishColorChange = (color, index, column) => {
        pageData[index][column] = color.hex;
        setPageData([...pageData]);
    }

    const syncWithMetaData = async () => {
        setLoading(true);

        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            try {
                await AreaUIConfServices.sync(activeORG);
                await loadPageData();
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const saveColor = async (index) => {
        setLoading(true);
        setItemSelected(-1);
        try {
            await AreaUIConfServices.update(pageData[index]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const saveUpdateIcon = async (iconURL) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[updateIconOfItem].icon_url = iconURL;
        try {
            await AreaUIConfServices.update(data_copy[updateIconOfItem]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setUpdateIconOfItem(-1);
        setLoading(false);
    }

    const activateItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = true;
        try {
            await AreaUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const disableItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = false;
        try {
            await AreaUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const copyConfFromOrg = () => {
        toastWarning('Not Implemented !!');
    }

    const renderTD = (item, index, column) => {
        return <td>
            <div className={classes.colorCube}
                 style={{background: item[column]}}
                 onClick={() => clickedColorDiv(index, column)}
            />
            {itemSelected === index && columnSelected === column && <div><SketchPicker
                color={item[column]}
                onChangeComplete={ color => finishColorChange(color, index, column) }
            />
                <button className={'level3-uic-color-picker-btn'}
                        onClick={()=>{saveColor(index)}}>Save</button>
            </div>}
        </td>
    }

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    const rowsWithIndices = filteredRows.map((row, index) => {
        return { ...row, sr: index + 1 };
    });
    const slicedRows = rowsWithIndices
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    // Find the object in allORGs that has the org_code matching activeORG
    const default_org = allORGs.find(org => org.split(" ")[0] === activeORG);

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>

                    <div style={{ width: '250px' }}>
                    {allORGs && allORGs.length > 0 && default_org && (
                        <Autocomplete
                        id="active_org"
                        value={default_org}
                        onChange={(event, newValue) => {
                            handleChangeOrgSelect(newValue);
                        }}
                        options={allORGs}
                        getOptionLabel={(option) => option} // assuming allORGs is an array of strings
                        className={classes.selectEmpty}
                        renderInput={(params) => (
                            <TextField 
                            {...params} 
                            label="Select Organization" 
                            variant="outlined" 
                            fullWidth             
                            />
                        )}
                        />
                    )}
                    </div>

                    <div className={classes.headerInputGroup}>

                        <Button
                            style={
                                {marginLeft: '10px', marginTop: '15px'}
                            }
                            variant="contained"  color="primary"
                            onClick={copyConfFromOrg}
                        >Copy Configs of Org?</Button>

                        <Button
                            style={
                                {marginLeft: '10px', marginTop: '15px'}
                            }
                            variant="contained"  color="primary"
                            onClick={syncWithMetaData}
                        >Sync with MetaData</Button>
                    </div>
                </div>
                <div className={classes.seaerchTextField}>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                    <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        placeholder="Search…"
                        className={classes.textField}
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                    onClick={() => setSearchText('')}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                </div>
            </div>

            <div className={classes.paper}>
                <h3>Areas [UI Configuration]</h3>
            </div>

            <Table>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        {
                        headers.map((item) =>
                            <EnhancedTableCell
                                id={item.id}
                                className={classes.table_cell_white} 
                                alignment={item.alignment}
                                sort={item.sort}
                                orderBy={orderBy}
                                order={order}
                                onRequestSort={handleRequestSort}
                            >
                                {item.label}
                            </EnhancedTableCell>
                        )
                        }
                        <TableCell className={classes.table_cell_white}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        slicedRows.map( (item, index) => (
                            <TableRow className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{item.area_code}</TableCell>
                                <TableCell>
                                    <img className={classes.imageDiv} src={renderURL(item.icon_url)}
                                         onClick={()=>{setUpdateIconOfItem(index)}}
                                    />
                                </TableCell>
                                {
                                    [
                                        renderTD(item, index, 'theme_color'),
                                        renderTD(item, index, 'badge_background'),
                                        renderTD(item, index, 'lock_background'),
                                        renderTD(item, index, 'lock_text_color'),
                                        renderTD(item, index, 'primary_color'),
                                        renderTD(item, index, 'primary_color_locked'),
                                        renderTD(item, index, 'secondary_color'),
                                        renderTD(item, index, 'secondary_color_locked'),
                                    ]
                                }
                                <TableCell>{formatDate(item.createdAt)},<br />{item.createdBy}</TableCell>
                                <TableCell>{formatDate(item.createdAt)},<br />{item.updatedBy}</TableCell>
                                {
                                    item.is_active ?
                                        <TableCell><Button onClick={()=>disableItem(index)}
                                                    className={classes.d_act_btn}>Disable</Button></TableCell> :
                                        <TableCell><Button onClick={()=>activateItem(index)}
                                                    className={classes.act_btn}>Activate</Button></TableCell>
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <LoaderWithBackDrop loading={loading} />

            <UploadImageDialogue
                openDialogue={updateIconOfItem !== -1}
                dialogueCloseHandler={()=>{setUpdateIconOfItem(-1)}}
                successCB={saveUpdateIcon}
            />

        </Container>
    );
}

export default Area_UI_Conf;
import React, {useState} from 'react';
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    Assess8_translations
} from "../../../services/metadata.service";
import {Link, useParams} from "react-router-dom";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api

const instrument_response_parser = (data) => {
    let str = data.trim();

    // const str_nl_arr = str.split('\n')
    // if (str_nl_arr.length > 1) {
    //     return str_nl_arr;
    // }

    let str_arr = str.split('{{');
    let output = []
    for (let i=1; i<str_arr.length; i++) {
        const option = str_arr[i].split('}}')[0];
        if (option.length > 1) {
            output.push(option);
        }
    }
    if (output.length === 0) {
        return data
    }
    return output;
}

const additional_json_rendering_of_IR = (json_obj) => {
    if (typeof json_obj['instrument_response'] === 'object') {
        json_obj['instrument_response'] = JSON.stringify(json_obj['instrument_response']);
        json_obj['instrument_response_type'] = 'JSON'
    } else {
        json_obj['instrument_response_type'] = 'STRING'
    }
    return json_obj;
}

const columns = [
    {name: 'Code', key: 0, excel_key: 'code', json_key: 'code', data_key: 'code'},
    {name: 'Locale', key: -1, excel_key: '', json_key: 'locale', data_key: 'locale'},
    {name: 'Instrument Question', key: 1, excel_key: 'Question', json_key: 'instrument_question', data_key: 'instrument_question'},
    {name: 'Q. subtitle Top', key: 2, excel_key: 'subtitle_top', json_key: 'question_subtitle_top', data_key: 'question_subtitle_top'},
    {name: 'Q. subtitle Bottom', key: 3, excel_key: 'subtitle_bottom', json_key: 'question_subtitle_bottom', data_key: 'question_subtitle_bottom'},
    {name: 'Instrument Response', key: 4, excel_key: 'Choices', json_key: 'instrument_response', data_key: 'instrument_response_data', data_parser: instrument_response_parser},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', sort: 'disabled', width: 150
    },
    {
        label: 'Locale',
        field: 'locale', sort: 'asc', width: 150
    },
    {
        label: 'Instrument Question',
        field: 'instrument_question', sort: 'disabled', width: 150
    },
    {
        label: 'Q. subtitle Top',
        field: 'question_subtitle_top', sort: 'asc', width: 150
    },
    {
        label: 'Q. subtitle Bottom',
        field: 'question_subtitle_bottom', sort: 'asc', width: 150
    },
    {
        label: 'Instrument Response',
        field: 'instrument_response', sort: 'disabled', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]



const Assess8_translationsPage = (props) => {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {

        let ir_output = '';
        if (row.instrument_response_type === 'JSON') {
            const ir_data_cp = JSON.parse(row.instrument_response);

            if (Array.isArray(ir_data_cp)) {
                let spans = [];
                for (let item of ir_data_cp) {
                    spans.push(<span className={'meta-data-table-array-span'}>{item}</span>);

                    ir_output += `(${item})`
                    ir_output += '\n'
                }
                row.instrument_response = spans;
            } else {
                row.instrument_response = ir_data_cp;
                ir_output = ir_data_cp;
            }
        } else {
            ir_output = row.instrument_response
        }
        row.instrument_response_data = ir_output;
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;
        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Assess 8 [Translations]'}
            modal_title={'Assess 8 - Translations Preview'}
            sheet_name={'assess_8_instruments'}
            columns={columns}
            tableCols={tableCols}
            jsonSpRender={additional_json_rendering_of_IR}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Assess8_translations.listData}
            postAPI={Assess8_translations.postData}
            deleteAPI={Assess8_translations.deleteEntity}
            updateAPI={Assess8_translations.updateEntity}
            preRemoveAPI={Assess8_translations.preRemoveCheck}
            populate_locale={true}
        />
    );
}

export default Assess8_translationsPage;



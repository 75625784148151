import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import {toastWarning, formatDate} from "../../../utils/utils";
import {OrgOrderServices, OrgCRUD_Services} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddOrderModal from '../components/AddOrderModal.comp';
import {useAuth} from "../../../context/auth";
import {LICENSES_ORDER_STATUS, LICENSES_ORDER_STATUS_VERBOSE} from "../../../utils/constants";
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));


const headers = [
    { id: "sr", label: "Sr.#", alignment: "left", sort: true },
    { id: "id", label: "Order ID", alignment: "left", sort: false },
    { id: "org_code", label: "Org Code", alignment: "left", sort: false },
    { id: "license_count", label: "License Count", alignment: "left", sort: true },
    { id: "license_type", label: "License Type", alignment: "left", sort: true },
    { id: "status", label: "Status", alignment: "left", sort: true },
    { id: "createdAt", label: "Created", alignment: "left", sort: true },
    { id: "updatedAt", label: "Updated", alignment: "left", sort: true },
]

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function PendingOrders(){

    const classes = useStyles();

    const { authUser } = useAuth();


    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("sr");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);

    const [ordersData, setOrdersData] = useState([]);

    const [showAddOrderModal, setShowAddOrderModal] = useState(false);


    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect( () => {
        loadOrdersData().then(d => console.log('orders data loaded!', d));
    }, []);


    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = ordersData.filter(field_filter_function);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };


    const loadOrdersData = async () => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.list_pending_orders();
            if (result.data && result.data.orders) {
                console.log('result.data.orders: ', result.data.orders)
                setOrdersData(result.data.orders);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const approveOrder = async (id) => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.approve_order(id);
            if (result.data && result.data.success) {
                let existingOrder = ordersData;
                for (let order of existingOrder) {
                    if (order.id === id) {
                        order.status = LICENSES_ORDER_STATUS.APPROVED
                    }
                }
                setOrdersData(existingOrder);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const rejectOrder = async (id) => {
        setLoading(true);
        try {
            const result = await OrgOrderServices.reject_order(id);
            if (result.data && result.data.success) {
                let existingOrder = ordersData;
                for (let order of existingOrder) {
                    if (order.id === id) {
                        order.status = LICENSES_ORDER_STATUS.REJECTED
                    }
                }
                setOrdersData(existingOrder);
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }



    const rowsWithIndices = filteredRows.map((row, index) => {
        return { ...row, sr: index + 1 };
    });
    const slicedRows = rowsWithIndices
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

            <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>


            </div>

            <div className={classes.seaerchTextField}>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                <TextField
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                onClick={() => setSearchText('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            </div>

            </div>



            <div className={classes.paper}>
                <h3>All Pending Orders</h3>
            </div>

            {
                <Table>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        {
                        headers.map((item) =>
                            <EnhancedTableCell
                                id={item.id}
                                className={classes.table_cell_white} 
                                alignment={item.alignment}
                                sort={item.sort}
                                orderBy={orderBy}
                                order={order}
                                onRequestSort={handleRequestSort}
                            >
                                {item.label}
                            </EnhancedTableCell>
                        )
                        }
                        <TableCell className={classes.table_cell_white}>Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            slicedRows.map( (order, index) => (
                                <TableRow className={true ? classes.tr_act : classes.tr_d_act}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{order.id}</TableCell>
                                    <TableCell>{order.org_code}</TableCell>
                                    <TableCell>{order.license_count}</TableCell>
                                    <TableCell>{order.license_type}</TableCell>
                                    <TableCell>{LICENSES_ORDER_STATUS_VERBOSE[order.status]}</TableCell>
                                    <TableCell>{formatDate(order.createdAt)},<br />{order.createdBy}</TableCell>
                                    <TableCell>{formatDate(order.createdAt)},<br />{order.updatedBy}</TableCell>
                                    {
                                        order.status === LICENSES_ORDER_STATUS.PENDING ? <TableCell>
                                            <Button onClick={()=>rejectOrder(order.id)}
                                                className={classes.d_act_btn}>Reject</Button>
                                            <Button onClick={()=>approveOrder(order.id)}
                                                className={classes.act_btn}>Approve</Button>
                                        </TableCell> : <TableCell>{"No Action!"}</TableCell>
                                    }

                                </TableRow>
                            ) )

                        }
                    </TableBody>
                </Table>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
};

export default PendingOrders;
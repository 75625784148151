import React, {useState} from 'react';

import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {Link, useParams} from "react-router-dom";
import {formatDate} from "../../../utils/utils";

import {
    Level3_translations
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Locale', key: -1, excel_key: '', json_key: 'locale', data_key: 'locale'},
    {name: 'Full Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', width: 150
    },
    {
        label: 'Locale',
        field: 'locale', sort: 'asc', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function Level3translationsPage() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        // const lvl3_code_data = row.code;
        // row.code_agg_l6 = <Link to={`/meta_data/level6/q=${lvl3_code_data}`} className={'meta-data-table-entity-link'} >{lvl3_code_data}</Link>;
        // row.code_agg_a8 = <Link to={`/meta_data/assess8/q=${lvl3_code_data}`} className={'meta-data-table-entity-link'} >{lvl3_code_data}</Link>;
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;
        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level 5 [Translations]'}
            modal_title={'Level 5 - Translations Preview'}
            sheet_name={'level_3'}
            columns={columns}
            tableCols={tableCols}
            search_query={search_query}
            tableSpRender={table_row__additional_rendering}
            listAPI={Level3_translations.listData}
            postAPI={Level3_translations.postData}
            deleteAPI={Level3_translations.deleteEntity}
            updateAPI={Level3_translations.updateEntity}
            preRemoveAPI={Level3_translations.preRemoveCheck}
            populate_locale={true}
        />
    );
}

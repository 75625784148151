import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import {getMapFromObjectListByKey, getPresenceMap, toastWarning} from "../../../utils/utils";
import {AllBadgesServices, OrgCRUD_Services} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";
import BadgeSelectionModal from '../components/BadgeSelectionModal.comp';
import {Level6} from "../../../services/metadata.service";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));

const headers = [
    { id: "sr", label: "Sr.#", alignment: "left", sort: true },
    { id: "code", label: "Code", alignment: "left", sort: false },
    { id: "app_badge_name", label: "Full Name", alignment: "left", sort: false },
    { id: "lvl3_code", label: "Area Code", alignment: "left", sort: false },
    { id: "license_cost_per_user", label: "License Cost", alignment: "left", sort: false },
]


function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function All_Badges(){

    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("sr");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG, setActiveORG] = useState("");

    const [openSelectionModal, setOpenSelectionModal] = useState(false);

    const [badgeCodesMap, setBadgeCodesMap] = useState({});
    const [allBadgesMap, setAllBadgesMap] = useState({});

    const [badgesMeta, setBadgesMeta] = useState([]);
    const [badgesMetaMap, setBadgesMetaMap] = useState({});
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
        loadBadgesMeta().then(d => console.log('pack-meta data loaded!', d));
    }, []);

    useEffect( () => {
        if (activeORG) {
            loadBadgeCodes().then(d => console.log('pack-codes data loaded!', d));
        }
    }, [activeORG]);

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                result.data.ORGs.map(item => item.org_code)
                const _all_orgs = result.data.ORGs.map(item => `${item.org_code} (${item.org_name})`);
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG(_all_orgs[0].split(" ")[0]);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadBadgesMeta = async () => {
        setLoading(true);

        try {
            const result = await Level6.listData();
            if (result.data && result.data.metaData) {
                const all_metadata = result.data.metaData
                setBadgesMeta(all_metadata);
                setBadgesMetaMap(getMapFromObjectListByKey(all_metadata, 'code'))
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const loadBadgeCodes = async () => {
        setLoading(true);

        if (activeORG !== '') {
            try {
                const result = await AllBadgesServices.listBadges(activeORG);
                if (result.data && result.data.badge_codes && result.data.all_badges) {
                    const codes = result.data.badge_codes;
                    // setPackCodes(codes)
                    setBadgeCodesMap(getPresenceMap(codes))
                    setAllBadgesMap(getMapFromObjectListByKey(result.data.all_badges, 'badge_code'))
                }
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const openSelectionClick = () => {
        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            setOpenSelectionModal(true);
        }
    }

    const handleChangeOrgSelect = (value) => {
        if (value) {
            const code = value.split(" ")[0];
            setActiveORG(code);
          }
    }

    const updatePresence = (code, added) => {
        const presence = {
            ...badgeCodesMap,
            [code]: added
        }
        setBadgeCodesMap(presence);
    }

    const saveChanges = async () => {
        setLoading(true);

        const presentCodes = badgeCodesMap;
        let badge_codes = [];
        for (let code of Object.keys(presentCodes)) {
            if (presentCodes[code]) {
                badge_codes.push(code);
            }
        }

        try {
            await AllBadgesServices.mapBadges(badge_codes, activeORG);
            setTimeout(()=>{
                loadBadgeCodes().then(d => console.log('pack-codes data loaded!', d));
            }, 200)
        } catch (e) {
            // error already toasted
        }

        setOpenSelectionModal(false);
        setLoading(false);
    }

    const syncPackageBadgesClick = async () => {
        setLoading(true);

        if (activeORG === '') {
            toastWarning('Please select an Organization first');
        } else {
            try {
                await AllBadgesServices.syncPackageBadges(activeORG);
                await loadBadgeCodes();
            } catch (e) {
                // error already toasted
            }
        }

        setLoading(false);
    }

    const badgeCodesArray = Object.keys(badgeCodesMap).filter(code => 
        badgeCodesMap[code] && badgesMetaMap[code] && allBadgesMap[code]
    ).map(code => badgesMetaMap[code]);
    const filteredRows = badgeCodesArray.filter(field_filter_function);

 
    const rowsWithIndices = filteredRows.map((row, index) => {
        return { ...row, sr: index + 1 };
    });
    const slicedRows = rowsWithIndices
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    // Find the object in allORGs that has the org_code matching activeORG
    const default_org = allORGs.find(org => org.split(" ")[0] === activeORG);

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>

                <div style={{ width: '250px' }}>
                {allORGs && allORGs.length > 0 && default_org && (
                    <Autocomplete
                    id="active_org"
                    value={default_org}
                    onChange={(event, newValue) => {
                        handleChangeOrgSelect(newValue);
                    }}
                    options={allORGs}
                    getOptionLabel={(option) => option} // assuming allORGs is an array of strings
                    className={classes.selectEmpty}
                    renderInput={(params) => (
                        <TextField 
                        {...params} 
                        label="Select Organization" 
                        variant="outlined" 
                        fullWidth             
                        />
                    )}
                    />
                )}
                </div>

                <div className={classes.headerInputGroup}>

                    <Button
                        style={
                            {marginLeft: '10px', marginTop: '15px'}
                        }
                        variant="contained"  color="primary"
                        onClick={syncPackageBadgesClick}
                    >Sync Package Badges</Button>
                    <Button
                        style={
                            {marginLeft: '10px', marginTop: '15px'}
                        }
                        variant="contained"  color="primary"
                        onClick={openSelectionClick}
                    >Select Badges</Button>
                </div>
                </div>
                <div className={classes.seaerchTextField}>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                    <TextField
                        variant="outlined"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        placeholder="Search…"
                        className={classes.textField}
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                    onClick={() => setSearchText('')}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                </div>
            </div>

            <div className={classes.paper}>
                <h3>All Badges</h3>
            </div>

            {
                badgeCodesMap && <Table>
                    <TableHead className={classes.table_head_bg}>
                        <TableRow>
                            {
                            headers.map((item) =>
                                <EnhancedTableCell
                                    id={item.id}
                                    className={classes.table_cell_white} 
                                    alignment={item.alignment}
                                    sort={item.sort}
                                    orderBy={orderBy}
                                    order={order}
                                    onRequestSort={handleRequestSort}
                                >
                                    {item.label}
                                </EnhancedTableCell>
                            )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            slicedRows.map((badgeMeta, index) => (
                                <TableRow className={true ? classes.tr_act : classes.tr_d_act}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{badgeMeta.code}</TableCell>
                                    <TableCell>{badgeMeta.app_badge_name}</TableCell>
                                    <TableCell>{badgeMeta.lvl3_code}</TableCell>
                                    <TableCell>{allBadgesMap[badgeMeta.code].license_cost_per_user}</TableCell>
                                </TableRow>
                            ))

                        }
                    </TableBody>
                </Table>
            }
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {
                <BadgeSelectionModal
                    open={openSelectionModal}
                    org_code={activeORG}
                    badgesMeta={badgesMeta}
                    badgeCodesMap={badgeCodesMap}
                    updatePresence={updatePresence}
                    saveChanges={saveChanges}
                    handleClose={ ()=> {
                        setOpenSelectionModal(false);
                    } }
                />
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default All_Badges;
import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useLocation} from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Select} from "@material-ui/core";

import { useFormik } from 'formik';
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    btn: {
        marginTop: 16
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function AddOrderModal(props) {

    const { open, createOrderCallback, handleClose } = props

    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            license_count: 0,
            order_details: '',
            license_type: 'DQP'
        },
        onSubmit: async (values, {resetForm}) => {

            createOrderCallback(values.license_count, values.license_type, values.order_details);

            resetForm();

        }
    });

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>

                        <h2 id="transition-modal-title">Create New Order</h2>
                        <br />
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        type="number"
                                        id="license_count"
                                        label="Licenses Count"
                                        name="license_count"
                                        onChange={formik.handleChange}
                                        value={formik.values['license_count']}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Select
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="license_type"
                                        label="Licenses Type"
                                        name="license_type"
                                        onChange={formik.handleChange}
                                        value={formik.values['license_type']}
                                    >
                                        {['DQW', 'DQP'].map(key => <option value={key}>{key}</option> )}
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="order_details"
                                        label="Order Details"
                                        type="text"
                                        multiline={true}
                                        rows={6}
                                        id="order_details"
                                        onChange={formik.handleChange}
                                        value={formik.values.order_details}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                </Grid>

                                <Grid item xs={4}>
                                </Grid>

                                <Grid item xs={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Create
                                    </Button>
                                </Grid>


                            </Grid>

                        </form>

                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default AddOrderModal;
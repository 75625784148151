import React, {useEffect, useState, useMemo} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';

import OrganizationModalForm from '../components/OrganizationModalForm.comp';
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {OrgCRUD_Services} from "../../../services/organization.service";
import {formatDate, toastWarning} from "../../../utils/utils";
import {NESTED_ACL_KEYS, USER_ACCESS_LEVEL, ORG_TYPE} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));


const headers = [
    { id: "org_code", label: "Code", alignment: "left", sort: true },
    { id: "org_name", label: "Name", alignment: "left", sort: true },
    { id: "app_key", label: "App Key", alignment: "left", sort: true },
    { id: "secret_key", label: "Secret Key", alignment: "left", sort: true },
    { id: "access_code", label: "Access Code", alignment: "left", sort: true },
    { id: 'licenses_total', label: 'Licenses Total', alignment: 'left', sort: true },
    { id: 'licenses_dqp_dqw', label: 'Licenses (DQP, DQW)', alignment: 'left', sort: true },
    { id: "createdAt", label: "Created", alignment: "left", sort: true },
    { id: "updatedAt", label: "Updated", alignment: "left", sort: true },
]


function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function List_ORG(){

    const classes = useStyles();

    const { authUser } = useAuth();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("org_code");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    const [objectData, setObjectData] = useState(undefined);
    const [openOrgForm, setOpenOrgForm] = useState(false);
    const [orgFormTitle, setOrgFormTitle] = useState('');

    const [showCreateBtn, setShowCreateBtn] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));

        if (authUser) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                perms[NESTED_ACL_KEYS.ORG_CRUD_CREATE] ? setShowCreateBtn(true) : setShowCreateBtn(false);
                perms[NESTED_ACL_KEYS.ORG_CRUD_UPDATE] ? setShowUpdateBtn(true) : setShowUpdateBtn(false);
                perms[NESTED_ACL_KEYS.ORG_CRUD_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
            } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
                setShowCreateBtn(false)
                setShowUpdateBtn(false)
                setShowDeleteBtn(false)
            } else {
                setShowCreateBtn(true)
                setShowUpdateBtn(true)
                setShowDeleteBtn(true)
            }
        }


    }, []);


    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = pageData.filter(field_filter_function);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const loadPageData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                console.log('all orgs --> ', result.data.ORGs);
                setPageData(result.data.ORGs);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const openCreateModal = () => {
        setObjectData({
            org_code: '',
            org_type: 1,
            contact_email: '',
            org_name: '',
            org_address: '',
            org_city: '',
            org_country: '',
            callback_url: '',
            callback_key: ''
        })
        setOpenOrgForm(true);
        setOrgFormTitle('Create New Organization');
    }

    const openEditModal = (record) => {
        setObjectData({
            id: record.id,
            org_code: record.org_code,
            org_type: record.type,
            contact_email: record.contact_email,
            org_name: record.org_name,
            org_address: record.org_address,
            org_city: record.org_city,
            org_country: record.org_country,
            callback_url: record.callback_url,
            callback_key: record.callback_key
        })
        setOpenOrgForm(true);
        setOrgFormTitle('Update Organization');
    }

    const invokeUpdateOrCreateService = async (data) => {
        console.log('invokeUpdateOrCreateService ..... with data --> ', data);

        setLoading(true);

        let close_operation = true;
        if (objectData.id){
            // invoke update API
            await OrgCRUD_Services.updateEntity(
                {
                    id: objectData.id,
                    ...data
                }
            );
            loadPageData().then(d => console.log('table data loaded!', d));
        } else {
            // invoke create API
            let valid_code = true;
            try {
                // pre-create check
                const api_1_res = await OrgCRUD_Services.preCreateCheck(data.org_code);
                if (api_1_res.data.deny) {
                    toastWarning(api_1_res.data.deny_message);
                    valid_code = false;
                    close_operation = false;
                }
            } catch (e) {
                close_operation = false;
                // error already toasted
            }

            if (valid_code) {
                try {
                    const new_entity = await OrgCRUD_Services.createEntity(data);
                    console.log('success fully created entity ---> ', new_entity);
                    loadPageData().then(d => console.log('table data loaded!', d));
                } catch (e) {
                    close_operation = false;
                    // error already toasted
                }
            }
        }

        if (close_operation) {
            setOpenOrgForm(false);
            setObjectData(undefined);
        }
        setLoading(false);
    }

    const invokeDeleteService = async (id) => {
        try {
            await OrgCRUD_Services.deleteEntity(id);
            loadPageData().then(d => console.log('table data loaded!', d));
        } catch (e) {
            // error already toasted
        }
    }

    const slicedRows = filteredRows
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination
    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div className={classes.headerInputGroup}>
                    {
                        showCreateBtn && <Button
                            variant="contained"  color="primary"
                            onClick={openCreateModal}
                        >Create</Button>
                    }
                </div>
                <div className={classes.seaerchTextField}>
                    <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <TextField
                            variant="outlined"
                            value={searchText}
                            onChange={(event) => setSearchText(event.target.value)}
                            placeholder="Search…"
                            className={classes.textField}
                            InputProps={{
                                startAdornment: <SearchIcon fontSize="small" />,
                                endAdornment: (
                                    <IconButton
                                        title="Clear"
                                        aria-label="Clear"
                                        size="small"
                                        style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                        onClick={() => setSearchText('')}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                </div>
            </div>
            <div className={classes.paper}>
                <h3>All Organizations</h3>
            </div>

            <Table>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        {
                        headers.map((item) =>
                            <EnhancedTableCell
                                id={item.id}
                                className={classes.table_cell_white} 
                                alignment={item.alignment}
                                sort={item.sort}
                                orderBy={orderBy}
                                order={order}
                                onRequestSort={handleRequestSort}
                            >
                                {item.label}
                            </EnhancedTableCell>
                        )
                        }
                        <TableCell className={classes.table_cell_white}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        slicedRows
                        .map( (item, index) => (
                            <TableRow className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                <TableCell>{item.org_code}</TableCell>
                                <TableCell>{item.org_name} ({ORG_TYPE[item.type]})</TableCell>
                                <TableCell>{item.app_key}</TableCell>
                                <TableCell>{item.secret_key}</TableCell>
                                <TableCell>{item.access_code}</TableCell>
                                <TableCell>
                                    DQP: {item.licenses_dq_assess.total}<br/>
                                    DQW: {item.licenses_dq_world.total}
                                </TableCell>
                                <TableCell>
                                    Used: {item.licenses_dq_assess.used}, {item.licenses_dq_world.used}<br/>
                                    Unused: {item.licenses_dq_assess.total - item.licenses_dq_assess.used}, {item.licenses_dq_world.total - item.licenses_dq_world.used}
                                </TableCell>
                                <TableCell>{formatDate(item.createdAt)},<br />{item.createdBy}</TableCell>
                                <TableCell>{formatDate(item.createdAt)},<br />{item.updatedBy}</TableCell>

                                <TableCell>
                                    <div>
                                    {
                                        showDeleteBtn && <DeleteIcon onClick={()=>invokeDeleteService(item.id)}
                                                                className={'meta-data-table-action-btn'} fontSize={'large'} color={'error'}>Delete</DeleteIcon>
                                    }
                                    {
                                        showUpdateBtn && <EditIcon onClick={()=>openEditModal(item)}
                                                                 className={'meta-data-table-action-btn'} fontSize={'large'} color={'primary'}>Edit</EditIcon>
                                    }
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {
                objectData && <OrganizationModalForm
                    open={openOrgForm}
                    title={orgFormTitle}
                    handleClose={ ()=> {
                        setOpenOrgForm(false);
                        setObjectData(undefined);
                    } }
                    submitCallback={invokeUpdateOrCreateService}
                    dataObject={objectData}
                />
            }

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default List_ORG;
import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {Table, TableBody, TableHead, TableRow, TableCell, TextField }from '@material-ui/core';
import {toastWarning} from "../../../utils/utils";
import {OrgCRUD_Services, BadgeUIConfServices, OrgConfigServices} from "../../../services/organization.service";
import Autocomplete from '@material-ui/lab/Autocomplete';
import UploadImageDialogue from "../components/uploadImageDialogue.comp";
import EditOrgConfigModal from "../components/EditOrgConfigModal.comp";

const useStyles = makeStyles((theme)=>({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        padding: '5px'
    },
    imageDiv: {
        height: 60,
        marginTop: -10
    },
    tr_act: {
        background: '#edf4ff',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));

function Config_ORG(){

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [allORGs, setAllORGs] = useState([]);
    const [activeORG_Obj, setActiveORG_Obj] = useState(undefined);
    const [activeORG_Code, setActiveORG_Code] = useState("");
    const [supportedLocales, setSupportedLocales] = useState("");
    const [showEditConfigModal, setShowEditConfigModal] = useState(false);
    const [editConfigDetails, setEditConfigDetails] = useState(undefined);

    useEffect( () => {
        loadOrgData().then(d => console.log('org data loaded!', d));
    }, []);

    const loadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.listData();
            if (result.data && result.data.ORGs) {
                const _all_orgs = result.data.ORGs
                setAllORGs(_all_orgs);

                if (_all_orgs.length > 0) {
                    setActiveORG_Obj(_all_orgs[0]);
                    setActiveORG_Code(_all_orgs[0].org_code)
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const reloadOrgData = async () => {
        setLoading(true);

        try {
            const result = await OrgCRUD_Services.reloadData();
            if (result.data && result.data.ORGs) {
                const _all_orgs = result.data.ORGs
                setAllORGs(_all_orgs);
                console.log(result.data.ORGs);
                const _active_org_object = _all_orgs.find((org) => org.org_code === activeORG_Code);
                if(_active_org_object) {
                    //console.log("Found", _active_org_object['contact_email']);
                    setActiveORG_Obj(_active_org_object);
                }
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleChangeOrgSelect = (value) => {
        if(value) {
            setActiveORG_Code(value.org_code);
            const target_orgs = allORGs.filter( item => item.org_code === value.org_code )
                if (target_orgs.length > 0) {
                    setActiveORG_Obj(target_orgs[0])

                    console.log('target_orgs[0] --> ', target_orgs[0]);
                }
            }
        }

    const Enable_UiConfigSection = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].ui_config_enable = true
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"ui_config_enable", true);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.ui_config_enable = true;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Disables_UiConfigSection = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].ui_config_enable = false
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"ui_config_enable", false);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.ui_config_enable = false;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Enable_DQWAccess = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].dqw_access = true
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"dqw_access", true);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.dqw_access = true;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Disable_DQWAccess = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].dqw_access = false
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"dqw_access", false);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.dqw_access = false;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Enable_UiPinned = async () => {
        setLoading(true);

        console.log(" org_code  dq-admin ",activeORG_Code);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].ui_pinned = true
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"ui_pinned", true);

            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.ui_pinned = true;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Disable_UiPinned = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].ui_pinned = false
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"ui_pinned", false);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.ui_pinned = false;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Enable_IsProgramLive = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].is_program_live = true
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"is_program_live", true);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.is_program_live = true;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Disable_IsProgramLive = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].is_program_live = false
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"is_program_live", false);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.is_program_live = false;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Enable_IsProfileApprovalRequire = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].is_profile_approval_require = true
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"is_profile_approval_require", true);
            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.is_profile_approval_require = true;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const Disable_IsProfileApprovalRequire = async () => {
        setLoading(true);

        let data_copy = allORGs;
        for (let i=0; i<data_copy.length; i++) {
            const org = data_copy[i]
            if (org.org_code === activeORG_Code) {
                data_copy[i].is_profile_approval_require = false
            }
        }
        try {
            await OrgConfigServices.update_config_flags(activeORG_Code,"is_profile_approval_require", false);

            setAllORGs([...data_copy]);
            const curr_org = activeORG_Obj;
            curr_org.is_profile_approval_require = false;
            setActiveORG_Obj({...curr_org})
        } catch (e) {
            // already toasted error
        }

        setLoading(false);
    }

    const editOrgConfig = ({label, value, callback}) => {
        if (activeORG_Code) {
            setShowEditConfigModal(true);
            setEditConfigDetails({label, value, callback});
        } else {
            toastWarning('in order to edit a config, please select an organization first!')
        }
    }

    // Find the object in allORGs that has the org_code matching activeORG_Code
    const default_org = allORGs.find(org => org.org_code === activeORG_Code);

    return (
        <Container component="main" maxWidth='false'>
            <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%'}}>

            <div style={{ width: '250px' }}>
            {allORGs && allORGs.length > 0 && default_org && (
                <Autocomplete
                id="active_org"
                value={default_org}
                onChange={(event, newValue) => {
                    handleChangeOrgSelect(newValue);
                }}
                options={allORGs}
                getOptionLabel={(option) => `${option.org_code} (${option.org_name})`} // assuming allORGs is an array of strings
                className={classes.selectEmpty}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Select Organization"
                    variant="outlined"
                    fullWidth
                    />
                )}
                />
            )}
            </div>

            <div className={classes.headerInputGroup}>
                {
                   <Button
                   style={
                    {marginLeft: '10px', marginTop: '15px'}
                    }
                        variant="contained" color="primary"
                        onClick={reloadOrgData}
                    >Reload</Button>
                }
            </div>

            </div>

            <div className={classes.paper}>
                <h3>Organization Configuration</h3>
            </div>

            <Table>
                <TableHead className={classes.table_head_bg}>
                    <TableRow>
                        <TableCell className={classes.table_cell_white}>Config Name</TableCell >
                        <TableCell className={classes.table_cell_white}>Current Value</TableCell >
                        <TableCell className={classes.table_cell_white}>Actions</TableCell >
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        activeORG_Obj && <TableRow className={classes.tr_act}>
                            <TableCell>{'Supported Locales'}</TableCell>
                            <TableCell>{activeORG_Obj.supported_locales.full_access ? 'All' : activeORG_Obj.supported_locales.allowed.join(', ')}</TableCell>
                            <TableCell>{'N/A'}</TableCell>
                        </TableRow>
                    }
                    {
                        activeORG_Obj && <TableRow className={classes.tr_act}>
                            <TableCell>{'Preferred Locale'}</TableCell>
                            <TableCell>{activeORG_Obj.preferred_locale ? activeORG_Obj.preferred_locale : 'Not set'}</TableCell>
                            <TableCell>{'N/A'}</TableCell>
                        </TableRow>
                    }
                    {
                        activeORG_Obj && activeORG_Obj.profile_auto_link && <TableRow className={activeORG_Obj.profile_auto_link.enable ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'Profile Auto Link'}</TableCell>
                            <TableCell>{activeORG_Obj.profile_auto_link.enable ? "Enabled" : "Disabled"}</TableCell>
                            <TableCell>{'N/A'}</TableCell>
                        </TableRow>
                    }
                    {
                        activeORG_Obj && activeORG_Obj.profile_auto_link && <TableRow className={activeORG_Obj.profile_auto_link.enable ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'Assessment Mode'}</TableCell>
                            <TableCell>{activeORG_Obj.dq_assess_mode ? "Yes" : "No"}</TableCell>
                            <TableCell>{'N/A'}</TableCell>
                        </TableRow>
                    }
                    {
                        activeORG_Obj && <TableRow className={activeORG_Obj.ui_config_enable ? classes.tr_act : classes.tr_d_act}>
                            <TableCell >{'UI Config Section'}</TableCell >
                            <TableCell >{activeORG_Obj.ui_config_enable ? 'Visible' : 'Hidden'}</TableCell >
                            {
                                activeORG_Obj.ui_config_enable ?
                                    <TableCell ><Button onClick={()=>Disables_UiConfigSection()}
                                                        className={classes.d_act_btn}>Disable</Button></TableCell > :
                                    <TableCell ><Button onClick={()=>Enable_UiConfigSection()}
                                                        className={classes.act_btn}>Activate</Button></TableCell >
                            }
                        </TableRow>
                    }
                    {
                        activeORG_Obj && <TableRow className={activeORG_Obj.dqw_access ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'DQ World Access'}</TableCell>
                            <TableCell>{activeORG_Obj.dqw_access ? 'Granted' : 'Revoked'}</TableCell>
                            {
                                activeORG_Obj.dqw_access ?
                                    <TableCell><Button onClick={()=>Disable_DQWAccess()}
                                                className={classes.d_act_btn}>Disable</Button></TableCell> :
                                    <TableCell><Button onClick={()=>Enable_DQWAccess()}
                                                className={classes.act_btn}>Activate</Button></TableCell>
                            }
                        </TableRow>
                    }
                    {
                        activeORG_Obj && activeORG_Obj.profile_auto_link && activeORG_Obj.profile_auto_link.enable && <TableRow className={activeORG_Obj.profile_auto_link.strategy_name ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'Profile Auto Link Strategy Name'}</TableCell>
                            <TableCell>{activeORG_Obj.profile_auto_link.strategy_name || "Not set"}</TableCell>
                            <TableCell>{'N/A'}</TableCell>
                        </TableRow>
                    }
                    {
                        activeORG_Obj && activeORG_Obj.profile_auto_link && activeORG_Obj.profile_auto_link.enable && <TableRow className={activeORG_Obj.profile_auto_link.strategy_params ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'Profile Auto Link Strategy Params'}</TableCell>
                            <TableCell>{activeORG_Obj.profile_auto_link.strategy_params ? JSON.stringify(activeORG_Obj.profile_auto_link.strategy_params) : "Not set"}</TableCell>
                            <TableCell>{'N/A'}</TableCell>
                        </TableRow>
                    }
                    {
                        activeORG_Obj && <TableRow className={activeORG_Obj.ui_pinned ? classes.tr_act : classes.tr_d_act}>

                        <TableCell>{'UI Pinned'}</TableCell>
                            <TableCell>

                                {activeORG_Obj.ui_pinned ? "Yes" : "No"}</TableCell>
                            {
                                activeORG_Obj.ui_pinned ?
                                    <TableCell ><Button onClick={()=>Disable_UiPinned()}
                                                        className={classes.d_act_btn}>Disable</Button></TableCell > :
                                    <TableCell ><Button onClick={()=>Enable_UiPinned()}
                                                        className={classes.act_btn}>Activate</Button></TableCell >
                            }
                        </TableRow>
                    }
                    {
                        activeORG_Obj && <TableRow className={activeORG_Obj.is_program_live ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'Program Live'}</TableCell>
                            <TableCell>{activeORG_Obj.is_program_live ? "Yes": "No"}</TableCell>
                            {
                                activeORG_Obj.is_program_live ?
                                    <TableCell ><Button onClick={()=>Disable_IsProgramLive()}
                                                        className={classes.d_act_btn}>Disable</Button></TableCell > :
                                    <TableCell ><Button onClick={()=>Enable_IsProgramLive()}
                                                        className={classes.act_btn}>Activate</Button></TableCell >
                            }
                        </TableRow>
                    }


                    {
                        activeORG_Obj && <TableRow className={activeORG_Obj.is_profile_approval_require ? classes.tr_act : classes.tr_d_act}>
                            <TableCell>{'Approval Required'}</TableCell>
                            <TableCell>{activeORG_Obj.is_profile_approval_require ? "Yes" : "No"}</TableCell>
                            {
                                activeORG_Obj.is_profile_approval_require ?
                                    <TableCell ><Button onClick={()=>Disable_IsProfileApprovalRequire()}
                                                        className={classes.d_act_btn}>Disable</Button></TableCell > :
                                    <TableCell ><Button onClick={()=>Enable_IsProfileApprovalRequire()}
                                                        className={classes.act_btn}>Activate</Button></TableCell >
                            }
                        </TableRow>
                    }



                </TableBody>
            </Table>

            {editConfigDetails && <EditOrgConfigModal
                open={showEditConfigModal && editConfigDetails}
                label={editConfigDetails.label}
                initialValue={editConfigDetails.value || ""}
                callback={editConfigDetails.callback}
                handleClose={ () => setShowEditConfigModal(false) }
            />}

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default Config_ORG;
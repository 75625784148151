import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    Switch,
    Tab,
    Tabs,
    TextField,
    Select,
    MenuItem,
    Paper,
    DialogActions, RadioGroup, Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {USER_ACCESS_LEVEL} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '80%'
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '100%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    dialogContent: {
        width: '100%',
    },
    resource: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    field: {
        marginTop: theme.spacing(1),
        '& .MuiInputBase-input': {
            paddingRight: '10px', // Adjust this value as needed
            minWidth: '430px'
        },
    },
    button: {
        marginTop: '10px',
        marginBottom: '10px'
    }
}));

const PermissionModal = ({ open, user, handleClose, sections, updatePermissionsCallback }) => {
    const classes = useStyles();
    const [fullAccess, setFullAccess] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [access, setAccess] = useState(
        sections.map(section =>
            section.resources.map(() => ({
                fullAccess: false,
                permissionLevel: 'operation',
                operations: 'Hide',
                fields: {},
            }))
        )
    );

    useEffect(() => {
        let assign_default_perms = true;

        if (user) {
            console.log('user.access_level: ', user.access_level);
            if (user.access_level === USER_ACCESS_LEVEL.FULL_ACCESS_ADMIN_PORTAL) {
                setFullAccess(true);
            } else {
                setFullAccess(false);
                const curr_perms = user.permissions;
                console.log('curr_perms: ', curr_perms);
                if (curr_perms.__rev && curr_perms.__rev === 2) {
                    generateAccessStateFromObject(curr_perms);
                    assign_default_perms = false;
                }
            }
        }

        if (assign_default_perms) {
            setAccess(sections.map(section =>
                section.resources.map(() => ({
                    fullAccess: false,
                    permissionLevel: 'operation',
                    operations: 'Hide',
                    fields: {},
                }))
            ));
        }

    }, [sections, user]);

    const handleFullAccessChange = (event) => {
        setFullAccess(event.target.checked);

        if (event.target.checked) {
            // When full access is granted, all sections and resources should also get full access
            setAccess(
                sections.map(section =>
                    section.resources.map(resource => ({
                        fullAccess: true,
                        permissionLevel: 'operation',
                        operations: resource.operation_resource.reduce((acc, operation) => {
                            acc[operation] = true;
                            return acc;
                        }, {}),
                        fields: resource.fields.reduce((acc, field) => {
                            acc[field.name] = field.operation_field[0]; // assuming that the first option is 'read'
                            return acc;
                        }, {}),
                    }))
                )
            );
        } else {
            // When full access is revoked, all sections and resources should also revoke full access
            setAccess(
                sections.map(section =>
                    section.resources.map(resource => ({
                        fullAccess: false,
                        permissionLevel: null,
                        operations: {},
                        fields: {},
                    }))
                )
            );
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleResourceFullAccessChange = (sectionIndex, resourceIndex, event) => {
        const updatedAccess = [...access];

        updatedAccess[sectionIndex][resourceIndex].fullAccess = event.target.checked;

        if (event.target.checked) {
            // When full access is granted to a resource, all operations and fields of that resource should also get full access
            updatedAccess[sectionIndex][resourceIndex].permissionLevel = 'operation';
            updatedAccess[sectionIndex][resourceIndex].operations = sections[sectionIndex].resources[resourceIndex].operation_resource.reduce((acc, operation) => {
                acc[operation] = true;
                return acc;
            }, {});
            updatedAccess[sectionIndex][resourceIndex].fields = sections[sectionIndex].resources[resourceIndex].fields.reduce((acc, field) => {
                acc[field.name] = field.operation_field[0]; // assuming that the first option is 'read'
                return acc;
            }, {});
        } else {
            // When full access is revoked from a resource, all operations and fields of that resource should also revoke full access
            updatedAccess[sectionIndex][resourceIndex].permissionLevel = null;
            updatedAccess[sectionIndex][resourceIndex].operations = {};
            updatedAccess[sectionIndex][resourceIndex].fields = {};
        }

        setAccess(updatedAccess);
    };

    const handlePermissionLevelChange = (sectionIndex, resourceIndex, event) => {
        const updatedAccess = [...access];
        updatedAccess[sectionIndex][resourceIndex].permissionLevel = event.target.value;
        setAccess(updatedAccess);
    };

    function handleOperationChange(sectionIndex, resourceIndex, value) {
        setAccess(prevAccess => {
            const newAccess = JSON.parse(JSON.stringify(prevAccess));  // Deep clone the previous state
            newAccess[sectionIndex][resourceIndex].operations = value;
            return newAccess;
        });
    }


    const handleAccessChange = (sectionIndex, resourceIndex, fieldName, newValue) => {
        const updatedAccess = [...access];
        updatedAccess[sectionIndex][resourceIndex].fields[fieldName] = newValue;
        setAccess(updatedAccess);
    };

    function generateAccessObject() {
        const accessObject = {};

        sections.forEach((section, sectionIndex) => {
            section.resources.forEach((resource, resourceIndex) => {
                accessObject[resource.key] = access[sectionIndex][resourceIndex];
            });
        });

        return accessObject;
    }

    const submitAccessChanges = () => {
        // console.log(access);

        const perms_data = generateAccessObject();
        console.log('perms_data: ', perms_data);
        updatePermissionsCallback({
            full_access: fullAccess,
            permissions: {
                '__rev': 2,
                ...perms_data
            }
        });
    };

    function generateAccessStateFromObject(accessObject) {
        const newState = sections.map((section) => {
            return section.resources.map((resource) => {
                // Get the permissions for the specific resource key from the accessObject
                const resourcePermissions = accessObject[resource.key];

                if (resourcePermissions) {
                    return resourcePermissions;
                } else {
                    // If there's no entry for a particular resource, you can return a default state for it
                    return {
                        fullAccess: false,
                        permissionLevel: 'operation', // or any default value
                        operations: {},
                        fields: {},
                    };
                }
            });
        });

        setAccess(newState);
    }


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{ paperWidthSm: classes.modal }}
        >
            <DialogTitle>Permissions</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Paper className={classes.modalPaper}>
                    {sections.length === 0 ? (
                        <Typography variant="body1" align="center">
                            No sections available.
                        </Typography>
                    ) : (
                        <>
                            <FormControlLabel
                                control={<Switch checked={fullAccess} onChange={handleFullAccessChange} />}
                                label="Full Access"
                            />
                            {!fullAccess && (
                                <Tabs
                                    value={activeTab}
                                    onChange={handleTabChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {sections.map((section, index) => (
                                        <Tab key={index} label={section.label} />
                                    ))}
                                </Tabs>
                            )}

                            {!fullAccess && sections[activeTab].resources.map((resource, resourceIndex) => (
                                <div key={resourceIndex}>
                                    <h3 className={classes.resource}>{resource.name}</h3>
                                    <FormControlLabel
                                        control={<Switch checked={access[activeTab]?.[resourceIndex]?.fullAccess || false} onChange={(event) => handleResourceFullAccessChange(activeTab, resourceIndex, event)} />}
                                        label="Full Access"
                                    />
                                    {!(access[activeTab]?.[resourceIndex]?.fullAccess || false) && (
                                        <div>
                                            <h4>Choose Permission Level</h4>
                                            <p style={{fontSize: '0.7rem', color: "red"}} >(Permission levels not functional in current release) <span style={{fontSize: '0.7rem', color: "green"}} > Use <b>Full Access</b> switch only.</span></p>

                                            <RadioGroup
                                                aria-label="permission-level"
                                                name="permission-level"
                                                value={access[activeTab]?.[resourceIndex]?.permissionLevel || ''}
                                                onChange={(event) => handlePermissionLevelChange(activeTab, resourceIndex, event)}
                                            >
                                                <FormControlLabel value="operation" control={<Radio />} label="Operation Level" />
                                                {sections[activeTab]?.resources[resourceIndex]?.fields.length > 0 && (
                                                    <FormControlLabel value="field" control={<Radio />} label="Field Level" />
                                                )}
                                            </RadioGroup>
                                            {access[activeTab]?.[resourceIndex]?.permissionLevel === 'operation' && (
                                                <div>
                                                    <TextField
                                                        select
                                                        className={classes.field}
                                                        label="Operation"
                                                        value={access[activeTab]?.[resourceIndex]?.operations || ''}
                                                        onChange={(event) => handleOperationChange(activeTab, resourceIndex, event.target.value)}
                                                    >
                                                        {resource.operation_resource.map((operation) => (
                                                            <MenuItem key={operation} value={operation}>
                                                                {operation}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            )}
                                            {access[activeTab]?.[resourceIndex]?.permissionLevel === 'field' && resource.fields.map((field, fieldIndex) => (
                                                <div key={field.name}>
                                                    <TextField
                                                        select
                                                        className={classes.field}
                                                        label={field.name}
                                                        value={access[activeTab]?.[resourceIndex]?.fields?.[field.name] || ''}
                                                        onChange={(event) => handleAccessChange(activeTab, resourceIndex, field.name, event.target.value)}
                                                    >
                                                        {field.operation_field.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}

                        </>
                    )}
                </Paper>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                {
                    sections.length === 0 ? (
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    ) : (
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={submitAccessChanges}
                        >
                            Save Changes
                        </Button>
                    )
                }

            </DialogActions>
        </Dialog>
    );
};

export default PermissionModal;

import {invokeApi} from "../bl_libs/invokeApi";
import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {use_e2ee} from "../utils/utils";

export const uploadMedia = (media, progressCb) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/admin/storage/put',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressCb
    };
    requestObj['postData'] = media;

    return invokeApi(requestObj);
}
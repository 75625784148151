import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    Level6_translations
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'Code', key: 0, json_key: 'code', data_key: 'code'},
    {name: 'Locale', key: -1, excel_key: '', json_key: 'locale', data_key: 'locale'},
    {name: 'Full Name', key: 1, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 2, json_key: 'definition', data_key: 'definition'},
    {name: 'App Badge Name', key: 3, json_key: 'app_badge_name', data_key: 'app_badge_name'},
    {name: 'App Badge Description', key: 4, json_key: 'app_badge_desc', data_key: 'app_badge_desc'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', sort: 'asc', width: 150
    },
    {
        label: 'Locale',
        field: 'locale', sort: 'asc', width: 150
    },
    {
        label: 'Full Name',
        field: 'full_name', sort: 'asc', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'App Badge Name',
        field: 'app_badge_name', sort: 'asc', width: 150
    },
    {
        label: 'App Badge Description',
        field: 'app_badge_desc', sort: 'disabled', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]

const Level6TranslationsPage = (props) => {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;
        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level 6 [Translations]'}
            modal_title={'Level 6 - Translations Preview'}
            sheet_name={'level_6'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Level6_translations.listData}
            postAPI={Level6_translations.postData}
            deleteAPI={Level6_translations.deleteEntity}
            updateAPI={Level6_translations.updateEntity}
            preRemoveAPI={Level6_translations.preRemoveCheck}
            populate_locale={true}
        />
    );
}

export default Level6TranslationsPage;

import React, {useState} from 'react';
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    Assess8
} from "../../../services/metadata.service";
import {Link, useParams} from "react-router-dom";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api

const instrument_response_parser = (data) => {
    let str = data.trim();

    // const str_nl_arr = str.split('\n')
    // if (str_nl_arr.length > 1) {
    //     return str_nl_arr;
    // }

    let str_arr = str.split('{{');
    let output = []
    for (let i=1; i<str_arr.length; i++) {
        const option = str_arr[i].split('}}')[0];
        if (option.length > 1) {
            output.push(option);
        }
    }
    if (output.length === 0) {
        return data
    }
    return output;
}

const instrument_question_parser = (data) => {
    let str = data.trim();

    let str_arr = str.split('{{');
    if (str_arr.length > 2) {
        let output = []
        for (let i=1; i<str_arr.length; i++) {
            const option = str_arr[i].split('}}')[0];
            if (option.length > 1) {
                output.push(option);
            }
        }
        if (output.length === 0) {
            return data
        }
        return output;
    } else {
        return str;
    }
}

const pre_test_parser = (data) => {
    let str = data.trim().toLowerCase();
    return str === 'pre';
}

const additional_json_rendering_of_IR = (json_obj) => {
    if (typeof json_obj['instrument_response'] === 'object') {
        json_obj['instrument_response'] = JSON.stringify(json_obj['instrument_response']);
        json_obj['instrument_response_type'] = 'JSON'
    } else {
        json_obj['instrument_response_type'] = 'STRING'
    }

    if (typeof json_obj['instrument_question'] === 'object') {
        json_obj['instrument_question'] = JSON.stringify(json_obj['instrument_question']);
        json_obj['instrument_question_type'] = 'JSON'
    } else {
        json_obj['instrument_question_type'] = 'STRING'
    }
    return json_obj;
}

// excel_key in list of columns is just for reference, performing no active function at this stage.
const columns = [
    {name: 'Source', key: 0, excel_key: 'Source', json_key: 'source', data_key: 'source'},
    {name: 'Source Order', key: 1, excel_key: 'Source Order', json_key: 'source_order', data_key: 'source_order'},
    {name: 'Program Order', key: 5, excel_key: 'Program Order within ProgL5', json_key: 'program_order', data_key: 'program_order'},
    {name: 'Old Program Lvl-1', key: 2, excel_key: 'OLD Program 1', json_key: 'old_program_1', data_key: 'old_program_1'},
    {name: 'Program Lvl-1', key: 3, excel_key: 'ProgL1', json_key: 'program_l1', data_key: 'program_l1'},
    {name: 'Level 5', key: 4, excel_key: 'ProgL5', json_key: 'level_5', data_key: 'level_5'},
    {name: 'ProgL5', key: 4, excel_key: 'ProgL5', json_key: 'prog_l5', data_key: 'prog_l5'},
    {name: 'Level 6', key: 6, excel_key: 'ProgL6', json_key: 'level_6', data_key: 'level_6'},
    {name: 'ProgL6', key: 6, excel_key: 'ProgL6', json_key: 'prog_l6', data_key: 'prog_l6'},
    {name: 'Level 8', key: 12, excel_key: 'Level 8', json_key: 'level_8', data_key: 'level_8'},
    {name: 'Level 9', key: 13, excel_key: 'Level 9', json_key: 'level_9', data_key: 'level_9'},

    {name: 'App Inclusion (Cat-13)', key: 7, excel_key: 'Category13 (AppInclusion) ', json_key: 'default_inclusion', data_key: 'default_inclusion'},
    {name: 'Importance', key: 8, excel_key: 'Category14 (Qimportance)', json_key: 'importance', data_key: 'importance'},

    {name: 'Selection Key (pre-post)', key: 9, excel_key: 'Pre-Post', json_key: 'selection_key', data_key: 'selection_key'},
    {name: 'DQC#', key: 10, excel_key: 'DQC#', json_key: 'dqc_no', data_key: 'dqc_no'},
    {name: 'GSM#', key: 11, excel_key: 'GSM#', json_key: 'gsm_no', data_key: 'gsm_no'},

    {name: 'Index-L10-TF', key: 14, excel_key: 'IndexL10_TF', json_key: 'index_lvl10_bool', data_key: 'index_lvl10_bool'},
    {name: 'Category 108', key: 15, excel_key: 'Category108 (TargetAudience)', json_key: 'category_108', data_key: 'category_108_data'},


    {name: 'Q. subtitle Top', key: 16, excel_key: 'Question(sub title 1 -top)', json_key: 'question_subtitle_top', data_key: 'question_subtitle_top'},
    {name: 'Q. subtitle Bottom', key: 17, excel_key: 'Question(sub title 2 - bottom)', json_key: 'question_subtitle_bottom', data_key: 'question_subtitle_bottom'},
    {name: 'Instrument Question', key: 20, excel_key: 'Final Instrument Question', json_key: 'instrument_question', data_key: 'instrument_question_data', data_parser: instrument_question_parser},
    {name: 'Instrument Response', key: 21, excel_key: 'Final Instrument Response ', json_key: 'instrument_response', data_key: 'instrument_response_data', data_parser: instrument_response_parser},
    {name: 'Category 11', key: 22, excel_key: 'Category11 (InstrumentType)', json_key: 'category_11', data_key: 'category_11_data'},
    {name: 'Category 115', key: 24, excel_key: 'Source', json_key: 'category_115', data_key: 'category_115'},
    {name: 'Correct Answer', key: 23, excel_key: 'Correct Answers', json_key: 'correct_answer', data_key: 'correct_answer'},

    {name: 'Raw Data Code (x-assess8)', key: 26, excel_key: 'Assess 10', json_key: 'raw_data_code', data_key: 'raw_data_code'},
    {name: 'Assess 10', key: 26, excel_key: 'Assess 10', json_key: 'assess_10', data_key: 'assess_10'},
    {name: 'Program Code (Ext Scr-code)', key: 25, excel_key: 'Program Code', json_key: 'external_score_code', data_key: 'external_score_code'},
    {name: 'Assess-10 x-data', key: 27, excel_key: '(Assess10) X Value Range', json_key: 'raw_data_value', data_key: 'raw_data_value'},
    {name: 'Assess-10 y-data', key: 28, excel_key: 'DQApp Score Code (Assess8) -(Y)', json_key: 'score_code', data_key: 'score_code'},
    {name: 'Score Function', key: 29, excel_key: 'Trasnformation Y = f(X) - Defined in ScoringFunction', json_key: 'score_function', data_key: 'score_function'},
    {name: 'YC Value', key: 31, excel_key: 'Transformation Function (YC = f(Y))', json_key: 'yc_value', data_key: 'yc_value'},

    {name: 'Programming Rules', key: 19, excel_key: 'Programming Rules', json_key: 'ui_rules', data_key: 'ui_rules'},





    //
    //
    // assess_10
]

const tableCols = [
    {
        label: 'revID',
        field: 'dq_rev_id', sort: 'asc', width: 150
    },
    {
        label: 'Code',
        field: 'code', sort: 'asc', width: 150
    },
    {
        label: 'DQC#',
        field: 'dqc_no', sort: 'asc', width: 150
    },
    {
        label: 'GSM#',
        field: 'gsm_no', sort: 'asc', width: 150
    },
    {
        label: 'Pre Test',
        field: 'pre_test_verbose', sort: 'asc', width: 150
    },
    {
        label: 'Score Code',
        field: 'score_code', sort: 'disabled', width: 150
    },
    {
        label: 'External Score Code',
        field: 'external_score_code', sort: 'disabled', width: 150
    },
    {
        label: 'External Ref ID',
        field: 'external_ref_id', sort: 'disabled', width: 150
    },
    {
        label: 'Source',
        field: 'source', sort: 'asc', width: 150
    },
    {
        label: 'Source Order',
        field: 'source_order', sort: 'asc', width: 150
    },
    {
        label: 'Program Order',
        field: 'program_order', sort: 'asc', width: 150
    },
    {
        label: 'Category 4',
        field: 'category_4', sort: 'asc', width: 150
    },
    {
        label: 'Level 5[a]',
        field: 'level_3', sort: 'asc', width: 150
    },
    {
        label: 'Prog L5',
        field: 'prog_l5', sort: 'asc', width: 150
    },
    {
        label: 'Prog L6',
        field: 'prog_l6', sort: 'asc', width: 150
    },
    {
        label: 'Level 7',
        field: 'level_7', sort: 'asc', width: 150
    },
    {
        label: 'Level 8',
        field: 'level_8', sort: 'asc', width: 150
    },
    {
        label: 'Category 108',
        field: 'category_108', sort: 'asc', width: 150
    },
    {
        label: 'Default Inclusion',
        field: 'default_inclusion', sort: 'asc', width: 150
    },
    {
        label: 'Importance',
        field: 'importance', sort: 'asc', width: 150
    },
    {
        label: 'Q. subtitle Top',
        field: 'question_subtitle_top', sort: 'asc', width: 150
    },
    {
        label: 'Q. subtitle Bottom',
        field: 'question_subtitle_bottom', sort: 'asc', width: 150
    },
    {
        label: 'Instrument Question',
        field: 'instrument_question', sort: 'disabled', width: 150
    },
    {
        label: 'Instrument Response',
        field: 'instrument_response', sort: 'disabled', width: 150
    },
    {
        label: 'Category 11',
        field: 'category_11', sort: 'asc', width: 150
    },
    {
        label: 'Category 115',
        field: 'category_115', sort: 'asc', width: 150
    },
    {
        label: 'Correct Answer',
        field: 'correct_answer', sort: 'disabled', searchable: false, width: 150
    },
    {
        label: 'Raw Data Code',
        field: 'raw_data_code', sort: 'disabled', width: 150
    },
    {
        label: 'Raw Data Value',
        field: 'raw_data_value', sort: 'disabled', width: 150
    },
    {
        label: 'Score Function',
        field: 'score_function', sort: 'disabled', width: 150
    },
    {
        label: 'YC Value',
        field: 'yc_value', sort: 'disabled', width: 150
    },
    {
        label: 'Programming Guide',
        field: 'ui_rules', sort: 'disabled', width: 150
    },
    {
        label: 'Selection Key',
        field: 'selection_key', sort: 'enable', width: 150
    },
    {
        label: 'DQC#',
        field: 'dqc_no', sort: 'enable', width: 150
    },
    {
        label: 'GSM#',
        field: 'gsm_no', sort: 'enable', width: 150
    },
    {
        label: 'level_9',
        field: 'level_9', sort: 'enable', width: 150
    },
    {
        label: 'Assess10',
        field: 'assess_10', sort: 'enable', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]



const Assess8Page = (props) => {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {

        let ir_output = '';
        let iq_output = '';
        if (row.instrument_response_type === 'JSON') {
            const ir_data_cp = JSON.parse(row.instrument_response);

            if (Array.isArray(ir_data_cp)) {
                let spans = [];
                for (let item of ir_data_cp) {
                    spans.push(<span className={'meta-data-table-array-span'}>{item}</span>);

                    ir_output += `{{${item}}}`
                    ir_output += '\n'
                }
                row.instrument_response = spans;
            } else {
                row.instrument_response = ir_data_cp;
                ir_output = ir_data_cp;
            }
        } else {
            ir_output = row.instrument_response
        }

        if (row.instrument_question_type === 'JSON') {
            const iq_data_cp = JSON.parse(row.instrument_question);

            if (Array.isArray(iq_data_cp)) {
                let spans = [];
                for (let item of iq_data_cp) {
                    spans.push(<span className={'meta-data-table-array-span'}>{item}</span>);

                    iq_output += `(${item})`
                    iq_output += '\n'
                }
                row.instrument_question = spans;
            } else {
                row.instrument_question = iq_data_cp;
                iq_output = iq_data_cp;
            }
        } else {
            iq_output = row.instrument_question
        }

        row.instrument_response_data = ir_output;
        row.instrument_question_data = iq_output;

        // const cat_4_data = row.category_4;
        // row.category_4_data = cat_4_data;
        // row.category_4 = <Link to={`/meta_data/cat4/q=${cat_4_data}`} className={'meta-data-table-entity-link'}>{cat_4_data}</Link>;
        //
        // const lvl_3_data = row.level_3;
        // row.level_3_data = lvl_3_data;
        // row.level_3 = <Link to={`/meta_data/level3/q=${lvl_3_data}`} className={'meta-data-table-entity-link'}>{lvl_3_data}</Link>;
        //
        // const lvl_5_data = row.level_5;
        // row.level_5_data = lvl_5_data;
        // // row.level_5 = <Link to={`/meta_data/level5/q=${lvl_5_data}`} className={'meta-data-table-entity-link'}>{lvl_5_data}</Link>;
        //
        // const lvl_6_data = row.level_6;
        // row.level_6_data = lvl_6_data;
        // row.level_6 = <Link to={`/meta_data/level6/q=${lvl_6_data}`} className={'meta-data-table-entity-link'}>{lvl_6_data}</Link>;
        //
        // const lvl_7_data = row.level_7;
        // row.level_7_data = lvl_7_data;
        // row.level_7 = <Link to={`/meta_data/level7/q=${lvl_7_data}`} className={'meta-data-table-entity-link'}>{lvl_7_data}</Link>;

        const cat_108_data = row.category_108;
        row.category_108_data = cat_108_data;
        row.category_108 = <Link to={`/meta_data/cat108/q=${cat_108_data}`} className={'meta-data-table-entity-link'}>{cat_108_data}</Link>;

        const cat_11_data = row.category_11;
        row.category_11_data = cat_11_data;
        row.category_11 = <Link to={`/meta_data/cat11/q=${cat_11_data}`} className={'meta-data-table-entity-link'}>{cat_11_data}</Link>;

        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Instruments Bank (All)'}
            modal_title={'Instruments Bank - Data Preview'}
            sheet_name={'InstrumentBank-All'}
            columns={columns}
            tableCols={tableCols}
            jsonSpRender={additional_json_rendering_of_IR}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={Assess8.listData}
            postAPI={Assess8.postData}
            deleteAPI={Assess8.deleteEntity}
            updateAPI={Assess8.updateEntity}
            preRemoveAPI={Assess8.preRemoveCheck}
        />
    );
}

export default Assess8Page;



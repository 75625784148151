import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {TableCell, TableSortLabel, Tooltip, Typography} from "@material-ui/core";
import {alphaNumericSort} from "../../utils/utils";

const CustomTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: '#233044',
        // color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableSortLabel = withStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        '&:hover': {
            opacity: 0.75,
        },
        '&& $icon': {
            opacity: 0.25,
            color: theme.palette.text.primary,
        },
        '&.MuiTableSortLabel-active': {
            // opacity: 1,
            // color: theme.palette.text.primary,
            // '&:hover': {
            //     opacity: 0.5,
            //     color: theme.palette.text.primary,
            // },
            '&& $icon': {
                color: theme.palette.text.primary,
            },
        },
    },
    icon: {}

}))(TableSortLabel);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

function descendingComparator(a, b, orderBy) {
    if(typeof a[orderBy] === "string" && typeof b[orderBy] === "string")
    {

        return b[orderBy].localeCompare(a[orderBy]);
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === "desc"
        ? alphaNumericSort(orderBy, true, true)
        : alphaNumericSort(orderBy, true, false);
}

const EnhancedTableCell = (props) => {

    const {id, className, alignment, disablePadding, sort, orderBy, order, onRequestSort, children} = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <CustomTableCell
            key={id}
            className={className}
            align={alignment}
            padding={disablePadding ? "none" : "default"}
            sortDirection={orderBy === id ? order : false}
        >
            {
                sort ?
                    <LightTooltip title={`Click to sort`} placement="top-start">
                        <CustomTableSortLabel
                            className={className}
                            active={orderBy === id}
                            direction={orderBy === id ? order : "asc"}
                            onClick={createSortHandler(id)}
                        >
                            {children}
                        </CustomTableSortLabel>
                    </LightTooltip>
                    : <Typography style={{ fontSize: '14px' }}>{children}</Typography>
            }
        </CustomTableCell>
    );
}


export default EnhancedTableCell;
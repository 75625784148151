import React, {useState, useEffect} from 'react';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, 
    TextField,  IconButton, Button }from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";

import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    tr_act: {
        background: '#e7fcde',
    },
    tr_d_act: {
        background: '#f8dede'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    table_head_bg: {
        background: '#4285f4'
    },
    table_cell_white: {
        color: 'white',
      },
}));


const headers = [
    { id: "id", label: "Id", alignment: "left", sort: true },
    { id: "code", label: "Code", alignment: "left", sort: true },
    { id: "app_badge_name", label: "Full Name", alignment: "left", sort: true },
    { id: "lvl3_code", label: "Area Code", alignment: "left", sort: true },
]


function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function BadgeSelectionModal(props) {

    const { open, handleClose, org_code, badgesMeta, badgeCodesMap, updatePresence, saveChanges } = props

    const classes = useStyles();

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("id");

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = badgesMeta.filter(field_filter_function);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const slicedRows = filteredRows
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className={classes.modalPaper}>

                        <h3 id="transition-modal-title">{'Selecting Badges For => ' + org_code}</h3>
                        <br />
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>

                        <Button
                            style={{
                                width: '25%',
                                marginBottom: '16px'
                            }}
                            variant="contained"  color="primary"
                            onClick={saveChanges}>Save Changes</Button>
                            <div className={classes.seaerchTextField}>
                            <Box display={'flex'} justifyContent={'flex-end'} mb={3}>
                                <TextField
                                    variant="outlined"
                                    value={searchText}
                                    onChange={(event) => setSearchText(event.target.value)}
                                    placeholder="Search…"
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: <SearchIcon fontSize="small" />,
                                        endAdornment: (
                                            <IconButton
                                                title="Clear"
                                                aria-label="Clear"
                                                size="small"
                                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                                onClick={() => setSearchText('')}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Box>
                            </div>
                            </div>

                        {
                            badgeCodesMap && <Table>
                                <TableHead className={classes.table_head_bg}>
                                    <TableRow>
                                        {
                                        headers.map((item) =>
                                            <EnhancedTableCell
                                                id={item.id}
                                                className={classes.table_cell_white} 
                                                alignment={item.alignment}
                                                sort={item.sort}
                                                orderBy={orderBy}
                                                order={order}
                                                onRequestSort={handleRequestSort}
                                            >
                                                {item.label}
                                            </EnhancedTableCell>
                                        )
                                        }
                                        <TableCell className={classes.table_cell_white}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        slicedRows.map( (item, index) => (
                                            <TableRow className={badgeCodesMap[item.code] ? classes.tr_act : classes.tr_d_act}>
                                                <TableCell>{item.id}</TableCell>
                                                <TableCell>{item.code}</TableCell>
                                                <TableCell>{item.app_badge_name}</TableCell>
                                                <TableCell>{item.lvl3_code}</TableCell>
                                                <TableCell>
                                                    {
                                                        badgeCodesMap[item.code] ?
                                                            <Button
                                                                onClick={()=>updatePresence(item.code, false)}
                                                                className={classes.d_act_btn}>{`Remove From=> ${org_code}`}</Button>
                                                            :
                                                            <Button
                                                                onClick={()=>updatePresence(item.code, true)}
                                                                className={classes.act_btn}>{`Add to=> ${org_code}`}</Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        }
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </div>

                </Fade>
            </Modal>
        </>
    );

}

export default BadgeSelectionModal